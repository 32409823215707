import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_493_68872)">
        <path fill="#fff" fillOpacity={0.01} d="M0 0H12V12H0z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.484 1.234a.375.375 0 01.531 0l4.5 4.5a.375.375 0 010 .53l-4.5 4.5a.376.376 0 11-.53-.53l4.234-4.235-4.235-4.234a.375.375 0 010-.531z"
          fill="#0D6EFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_493_68872">
          <path fill="#fff" d="M0 0H12V12H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgComponent
