import React, {useState,useCallback, useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import './WordsEditPage.css'
import EditIcon from '../svg/EditSvg';
import AddSvg from '../svg/AddSvg';
import '../../node_modules/react-grid-layout/css/styles.css';
import '../../node_modules/react-resizable/css/styles.css';
import _ from 'lodash';
import {fetchWordById, updateWordById, uploadPhoto} from '../services/request.service';
import AddSectionPopup from './components/AddSectionPopup';
import {SECTION_TYPE} from '../dto/section.dto';
import EditContentSection from './components/EditContentSection';
import EditWordCardSection from './components/EditWordCardSection';
import EditMediaSection from './components/EditMediaSection';
import EditVideoUrlSection from './components/EditVideoUrlSection';
import LoadingPage from './components/LoadingPage';
import FileDropzone from './components/UploadFileDropZone';
import PicFileLogoSvg from '../svg/PicFileLogoSvg';
import RemoveSvg from '../svg/RemoveIconSvg';
import {server_ip} from '../config';
import {QRCodeCanvas} from 'qrcode.react';
import DownloadSvg from '../svg/DownloadSvg';
import FileDragableZone from './components/UploadFileDragZone'

function Component(props) {
  const navigate = useNavigate();
  let { id } = useParams();
  const [data,setdata] = useState({
    text: "unknown"
  })
  const [isLoading,setisLoading]=useState(false)
  const fetchData=async(force=false)=>{
    if(isLoading&&!force)return
    setisLoading(true)
    let result = await fetchWordById(props.token.token,id);
    if(result.result){
        setdata(result.result)
    }else{
        alert('Fetch data error')
    }
    setisLoading(false)
  }
  useEffect(()=>{
    fetchData();
  },[id])


  const [isAddingSection,setisAddingSection]=useState(false);
  const handleStartAddSection=()=>{setisAddingSection(true)};
  const handleCancelAddSection=()=>{setisAddingSection(false)};
  const handleConfirmAddSection=(type)=>{
    let d = {...data}
    let sections = d.sections||[];
    sections.push(
        type===SECTION_TYPE.WORDCARD
        ?{ type, cards: [{key:"形似",value:""},{key:"音似",value:""},{key:"近義/ 同義",value:""}] }
        :{ type }
    )
    d.sections = sections
    setdata(d)
    setisAddingSection(false);
  }
  const handleTitleChange=(item,index)=>(e)=>{
    let d = {...data}
    d.sections[index].title=e.target.value;
    setdata(d)
  }
  const handleContentChange=(item,index)=>(e)=>{
    let d = {...data}
    d.sections[index].content=e.target.value;
    setdata(d)
  }
  const handleSectionUp=(item,index)=>()=>{
    if(index===0)return
    let d = {...data}
    let sections = d.sections;
    let prev = sections[index-1]
    sections[index-1]=sections[index]
    sections[index]=prev
    d.sections=sections;
    setdata(d)
  }
  const handleSectionDown=(item,index)=>()=>{
    if(index===data.sections.length-1)return
    let d = {...data}
    let sections = d.sections;
    let next = sections[index+1]
    sections[index+1]=sections[index]
    sections[index]=next
    d.sections=sections;
    setdata(d)
  }
  const handleSectionDelete =(item,index)=>()=>{
    let d = {...data}
    let sections = d.sections;
    sections.splice(index, 1);
    d.sections=sections;
    setdata(d)
  }
  
  const handleSectionCopy = (item,index)=>()=>{
    let d = {...data}
    d.sections.push(JSON.parse(JSON.stringify(d.sections[index])))
    setdata(d)
  }
  const handleSectionWordCardOrder=(item,index)=>(oldLayout,newLayout)=>{
    let array=[]
    newLayout.sort((a,b)=>{if(a.y<b.y){return -1;}else if(b.y<a.y){return 1;}return 0;})
    for(let i of newLayout){
        let oldIndex = _.findIndex(oldLayout,{'i':i.i});
        array.push(data.sections[index].cards[oldIndex]);
    }
    let d={...data};
    d.sections[index].cards=array;
    setdata(d);
  }
  const handleSectionEditKey=(item,index)=>(card,cardIndex,e)=>{
    let d={...data};
    d.sections[index].cards[cardIndex].key=e.target.value
    setdata(d);
  }
  const handleSectionEditValue=(item,index)=>(card,cardIndex,e)=>{
    let d={...data};
    d.sections[index].cards[cardIndex].value=e.target.value
    setdata(d);
  }
  const handleSectionAddCard=(item,index)=>()=>{
    let d={...data};
    let sections=[...d.sections]
    sections[index].cards.push({key:'',value:''})
    d.sections=sections
    setdata(d);
  }
  const handleSectionDeleteCard=(item,index)=>(card,cardIndex)=>{
    let d = {...data}
    let cards = d.sections[index].cards;
    cards.splice(cardIndex, 1);
    d.sections[index].cards=cards;
    setdata(d)
  }
  const handleFileDrop =(item,index)=> (files)=>{
    let d={...data}
    d.sections[index].files=[...(d.sections[index].files||[]),...files]
    setdata(d)
    console.log(files)
  }
  const handleRemoveFile = (item,index)=> (fileIndex)=>{
    let d = {...data}
    let files = d.sections[index].files;
    files.splice(fileIndex, 1);
    d.sections[index].files=files;
    setdata(d)
  }
  const handleFilesOrder=(item,index)=>(oldLayout,newLayout)=>{
    let array=[]
    newLayout.sort((a,b)=>{if(a.x<b.x){return -1;}else if(b.x<a.x){return 1;}return 0;})
    for(let i of newLayout){
        let oldIndex = _.findIndex(oldLayout,{'i':i.i});
        array.push(data.sections[index].files[oldIndex]);
    }
    let d={...data};
    d.sections[index].files=array;
    setdata(d);
  }

  const handleReset=()=>{fetchData(true);}
  const handleCancel=()=>{navigate(`/words/details/${id}`);}
  const handleConfirm=async()=>{
    if(isLoading)return
    setisLoading(true)


    //handle upload image
    if(data?.image&&!data?.image?.uploadDate){
        let file = data.image;
        var formData = new FormData();
        const newFilename =`${id}_${Date.now()}_${Math.floor(Math.random() * 100)}.${file.type.split('/')[1]}`; 
        formData.append('json', JSON.stringify({filename:newFilename}));
        formData.append('file', file);
        let uploadResult = await uploadPhoto(props.token.token,formData)
        if(uploadResult.errno){
            alert('upload error')
            setisLoading(false);
            return
        }
        data.image = {
            filename: newFilename,
            originalFilename: file.name,
            size: file.size,
            type: file.type,
            uploadDate: new Date()
        }
    }
    //handle upload section files
    for(let section_index in data.sections||[]){
        let section = data.sections[section_index]
        if(section.type===SECTION_TYPE.MEDIA){
            for(let file_index in section.files||[]){
                let file = section.files[file_index]
                if(!file.uploadDate){
                    var formData = new FormData();
                    const newFilename =`${id}_${Date.now()}_${Math.floor(Math.random() * 100)}.${file.type.split('/')[1]}`; 
                    formData.append('json', JSON.stringify({filename:newFilename}));
                    formData.append('file', file);
                    let uploadResult = await uploadPhoto(props.token.token,formData)
                    if(uploadResult.errno){
                        alert('upload error')
                        setisLoading(false);
                        return
                    }
                    data.sections[section_index].files[file_index]= {
                        filename: newFilename,
                        originalFilename: file.name,
                        size: file.size,
                        type: file.type,
                        uploadDate: new Date()
                    }

                }
            }
        }
    }
    //handle upload animations image
    for(let file_index in data.animations||[]){
        let file = data.animations[file_index]
        if(!file.uploadDate){
            var formData = new FormData();
            const newFilename =`${id}_${Date.now()}_${Math.floor(Math.random() * 100)}.${file.type.split('/')[1]}`; 
            formData.append('json', JSON.stringify({filename:newFilename}));
            formData.append('file', file);
            let uploadResult = await uploadPhoto(props.token.token,formData)
            if(uploadResult.errno){
                alert('upload error')
                setisLoading(false);
                return
            }
            data.animations[file_index]= {
                filename: newFilename,
                originalFilename: file.name,
                size: file.size,
                type: file.type,
                uploadDate: new Date()
            }
        }
    }

    const updateData={
        strokes:Number(data.strokes)||0,
        sections: data.sections||[],
        animations: data.animations||[],
    }
    if(data.image)updateData.image=data.image
    let result = await updateWordById(props.token.token,id,updateData);
    if(result.result){
        navigate(`/words/details/${id}`);
    }else{
        alert('Update error');
        setisLoading(false);
    }
  }
  const onDropWordFile = (files) =>{
    if(files.length>1){
        alert(`Only one file is allowed.`)
        return
    }
    const file= files[0]
    if(!['image/png','image/jpeg','image/gif'].includes(file.type)){
        alert(`File type ${file.type} is not allowed`)
        return
    }
    if(file.size>1024*1024*2){
        alert(`File size must be within 2 MB`)
        return
    }
    
    let d={...data}
    d.image=files[0]
    setdata(d)
  }
  const onDropAnimationFile = (files) =>{
    for(let newFile of files){
        if(!['image/png','image/jpeg','image/gif'].includes(newFile.type)){
            alert(`File type ${newFile.type} is not allowed`)
            return
        }
        if(newFile.size>1024*1024*2){
            alert(`File size must be within 2 MB`)
            return
        }
    }
    //check if file name already exists
    for(let f of data?.animations?.files||[]){
        for(let newFile of files){
            if(f.name === newFile.name){
                alert('File with same filename already exists')
                return
            }
        }
    }
    let d={...data}
    d.animations=[...(d.animations||[]),...files]
    setdata(d)
  }

  const handleRemoveWordImage =() =>{
    let d={...data}
    delete d.image
    setdata(d)
  }

  const handleDownloadQrCode=()=>{
    const canvas = document.querySelector('.HpQrcode > canvas')
    var element = document.createElement('a');
    element.href = canvas.toDataURL();
    element.download = `${data.text}_qrcode.png`;
    document.body.appendChild(element);
    element.click();
  }

  const handleUpdateAnimationList=(oldLayout,newLayout)=>{
    let array=[]
    newLayout.sort((a,b)=>{if(a.x<b.x){return -1;}else if(b.x<a.x){return 1;}return 0;})
    for(let i of newLayout){
        let oldIndex = _.findIndex(oldLayout,{'i':i.i});
        array.push(data.animations[oldIndex]);
    }
    let d={...data};
    d.animations=array;
    setdata(d);
  }
  const handleRemoveAnimation =(fileIndex)=>{
    let d = {...data}
    let files = d.animations;
    files.splice(fileIndex, 1);
    d.animations=files;
    setdata(d)
  }
  const handleEditVideoUrl =(item,index)=>(e)=>{
    let d = {...data}
    d.sections[index].url=e.target.value;
    setdata(d)
  }

  const handleStrokeInput =(e)=>{
    let num=Number(e.target.value)
    setdata({...data,strokes:num<0?0:num})
  }

  return (
    <div className='wordslist_root_'>
        <div className='wordslist_root'>
            <div className='row_spacebetween'>
                <div className='common_title'>
                    字卡: {data.text}
                    {/* <div className='common_button mousehover' style={{background:'#ED6060',marginLeft:60}}>
                        編輯中
                    </div> */}
                </div>
                <div className='common_button mousehover' style={{background:"#EAEAEA"}}>
                    <EditIcon/>
                    編輯
                </div>
            </div>

            <div className='worddetail_main'>
                <div className='worddetail_left'>
                   
                    <div style={{width:"100%",height:20}}/>
                    <div className='worddetail_strokecontainer'>
                        筆畫:
                        <input value={data.strokes||'0'} onChange={handleStrokeInput} type='number' style={{width:70,textAlign:'right',padding:'0 10px'}} placeholder='筆畫'/>
                    </div>
                    <div style={{width:"100%",height:20}}/>


                    
                    {data?.sections?.map((item,index)=>
                        item.type===SECTION_TYPE.CONTENT
                        ?<EditContentSection 
                            key={index}
                            data={item}
                            onEditTitle={handleTitleChange(item,index)}
                            onEditContent={handleContentChange(item,index)}
                            handleUp={handleSectionUp(item,index)}
                            handleDown={handleSectionDown(item,index)}
                            handleDelete={handleSectionDelete(item,index)}
                            handleCopy={handleSectionCopy(item,index)}
                        />
                        :item.type===SECTION_TYPE.WORDCARD
                        ?<EditWordCardSection
                            key={index}
                            data={data}
                            index={index}
                            handleUp={handleSectionUp(item,index)}
                            handleDown={handleSectionDown(item,index)}
                            handleDelete={handleSectionDelete(item,index)}
                            handleCopy={handleSectionCopy(item,index)}
                            updateWordCardOrder={handleSectionWordCardOrder(item,index)}
                            onEditKey={handleSectionEditKey(item,index)}
                            onEditValue={handleSectionEditValue(item,index)}
                            onAddCard={handleSectionAddCard(item,index)}
                            onDelete={handleSectionDeleteCard(item,index)}
                        />
                        :item.type===SECTION_TYPE.MEDIA
                        ?<EditMediaSection
                            key={index}
                            data={data}
                            index={index}
                            handleUp={handleSectionUp(item,index)}
                            handleDown={handleSectionDown(item,index)}
                            handleDelete={handleSectionDelete(item,index)}
                            handleCopy={handleSectionCopy(item,index)}
                            onFileDrop={handleFileDrop(item,index)}
                            onRemoveFile={handleRemoveFile(item,index)}
                            onFilesOrderUpdate={handleFilesOrder(item,index)}
                        />
                        :item.type===SECTION_TYPE.VIDEOURL
                        ?<EditVideoUrlSection
                            key={index}
                            data={item}
                            onEditTitle={handleEditVideoUrl(item,index)}
                            handleUp={handleSectionUp(item,index)}
                            handleDown={handleSectionDown(item,index)}
                            handleDelete={handleSectionDelete(item,index)}
                            handleCopy={handleSectionCopy(item,index)}
                        />
                        :<div key={index}>
                            {item.type}
                        </div>
                    )}
                    
                    <div
                        className='mousehover'
                        onClick={handleStartAddSection}
                        style={{backgroundColor:"#FBBF24",width:177,height:36,borderRadius:6,marginTop:10,marginBottom:50,color: '#FFFFFF',fontSize:12,fontWeight:'700',display:'flex',justifyContent:'center',alignItems:"center"}
                    }>
                        <AddSvg/>&nbsp;&nbsp;增加欄目
                    </div>
                </div>


                
                {/*right side of the screen*/}
                <div className='worddetail_right'>
                    <div className='worddetail_subtitle'>
                        字卡圖片
                    </div>
                    {data?.image&&<div className='worddetail_imagecontainer' style={{position:"relative"}}>
                        {
                           data?.image?.filename
                           ?<img style={{height:200,width:200,objectFit:'contain'}} src={`${server_ip}/api/documents/${data?.image?.filename}`} alt='image'/>
                           :<div>
                                <PicFileLogoSvg/>
                            </div>
                        }
                        <div className='mousehover' style={{position:'absolute',top:5,right:5}} onClick={handleRemoveWordImage}>
                            <RemoveSvg/>
                        </div>
                    </div>}
                    <div className='worddetail_imagecontainer' style={{height:110,backgroundColor:'white',border:'1px solid #E5E5E5'}}>
                        <div className='wordedit_draganddropzone_container'>
                            <FileDropzone data={data} onDropFile={onDropWordFile}/>
                        </div>
                    </div>

                    <div className='worddetail_subtitle'>
                        筆劃動畫
                    </div>
                    {data?.animations?.length>0&&<div className='worddetail_imagecontainer' style={{height:140}}>
                        <div className='worddetail_drag_txt'>drag and drop to re-arrange the order</div>
                        <div className='wordedit_draganddropzone_container'>
                            <FileDragableZone 
                                data={data}
                                isAnimation={true}
                                updateFileList={handleUpdateAnimationList} 
                                handleRemoveFile={handleRemoveAnimation}
                            />
                        </div>
                    </div>}
                    <div className='worddetail_imagecontainer' style={{height:110,backgroundColor:'white',border:'1px solid #E5E5E5'}}>
                        <div className='wordedit_draganddropzone_container'>
                            <FileDropzone data={data} onDropFile={onDropAnimationFile}/>
                        </div>
                    </div>
                    <div className='worddetail_subtitle'>
                        QR code
                    </div>
                    <div className='HpQrcode worddetail_imagecontainer' style={{position:'relative'}}>
                        <QRCodeCanvas value={`TXT:${data?.text}SEN:${id}`} />
                        <div className='mousehover' style={{position:'absolute',bottom:5,right:5}} onClick={handleDownloadQrCode}>
                            <DownloadSvg/>
                        </div>
                    </div>
                </div>

            </div>
            {/*Buttons*/}
            <div style={{width:'100%',boxSizing:"border-box",display:'flex',flexDirection:'row',justifyContent:'center',marginTop:50,marginBottom:50}}>
                <div
                    className='mousehover'
                    onClick={handleReset}
                    style={{padding:'10px 16px',borderRadius:6,color:'#EF4444',border:'1px solid #EF4444',userSelect: 'none'}}
                >
                    重新填寫
                </div>
                <div
                    className='mousehover'
                    onClick={handleCancel}
                    style={{padding:'10px 16px',borderRadius:6,color:'#404040',border:'1px solid #E5E5E5',margin:"0px 20px",userSelect: 'none'}}
                >
                    取消
                </div>
                <div
                    className='mousehover'
                    onClick={handleConfirm}
                    style={{padding:'10px 16px',backgroundColor:'#6366F1',borderRadius:6,color:'white',border:'1px solid #6366F1',userSelect: 'none'}}
                >
                    確認
                </div>
            </div>
            {/*End of Buttons*/}
        </div>
        {isAddingSection&&<AddSectionPopup handleCancel={handleCancelAddSection} handleConfirm={handleConfirmAddSection}/>}
        {isLoading&&<LoadingPage/>}
    </div>
  );
}

export default connect((store)=>{
    return{
      token:store.tokenReducer,
    }
  })(Component)
  