import React, { useState, useEffect} from 'react';
import { connect } from 'react-redux';
import CrossSvg from '../../svg/CrossSvg'
import {SECTION_TYPE} from '../../dto/section.dto'
function Component(props) {

    const [type,setType]=useState(SECTION_TYPE.CONTENT)
    const handleCancel=()=>{if(props.handleCancel)props.handleCancel()};
    const handleSelect=(type)=>()=>{setType(type)}
    const handleConfirm=async ()=>{if(props.handleConfirm)props.handleConfirm(type)}
    return (
        <div style={{position:'fixed',top:0,bottom:0,left:0,right:0,backgroundColor: 'rgba(0, 0, 0, 0.5)',display:'flex',justifyContent:"center",alignItems:'center'}}>
            <div style={{padding:'30px 32px',display:'flex',width:'100%',maxWidth:700,backgroundColor:'white',borderRadius:10,flexDirection:'column',alignItems:'center',boxSizing:'border-box',position:"relative"}}>
                {/*Title*/}
                <div style={{color: '#171717',fontWeight:800,fontSize:36,width:"100%",boxSizing:'border-box',textAlign:'left',userSelect: 'none',marginBottom:50}}>
                    增加欄目
                </div>
                {/*End of Title*/}

                {/*type options*/}
                <div style={{width:'100%',display:'flex',flexDirection:'row',boxSizing:'border-box',justifyContent:'flex-start',alignItems:'center',color: '#171717',fontSize:20,fontWeight:'700'}}>
                    <div className='mousehover' onClick={handleSelect(SECTION_TYPE.CONTENT)} style={{width:25,height:25,borderRadius:99,backgroundColor:"#D9D9D9",display:'flex',justifyContent:'center',alignItems:'center',margin:'12px 0px'}}>
                        {type===SECTION_TYPE.CONTENT&&<div style={{width:15,height:15,borderRadius:99,backgroundColor:"#6366F1"}}/>}
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;標題 & 內容
                </div>

                {props.noWordCard||<div style={{width:'100%',display:'flex',flexDirection:'row',boxSizing:'border-box',justifyContent:'flex-start',alignItems:'center',color: '#171717',fontSize:20,fontWeight:'700',margin:'12px 0px'}}>
                    <div className='mousehover' onClick={handleSelect(SECTION_TYPE.WORDCARD)} style={{width:25,height:25,borderRadius:99,backgroundColor:"#D9D9D9",display:'flex',justifyContent:'center',alignItems:'center'}}>
                        {type===SECTION_TYPE.WORDCARD&&<div style={{width:15,height:15,borderRadius:99,backgroundColor:"#6366F1"}}/>}
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;字卡
                </div>}

                <div style={{width:'100%',display:'flex',flexDirection:'row',boxSizing:'border-box',justifyContent:'flex-start',alignItems:'center',color: '#171717',fontSize:20,fontWeight:'700'}}>
                    <div className='mousehover' onClick={handleSelect(SECTION_TYPE.MEDIA)} style={{width:25,height:25,borderRadius:99,backgroundColor:"#D9D9D9",display:'flex',justifyContent:'center',alignItems:'center',margin:'12px 0px'}}>
                        {type===SECTION_TYPE.MEDIA&&<div style={{width:15,height:15,borderRadius:99,backgroundColor:"#6366F1"}}/>}
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;多媒體檔案
                </div>

                <div style={{width:'100%',display:'flex',flexDirection:'row',boxSizing:'border-box',justifyContent:'flex-start',alignItems:'center',color: '#171717',fontSize:20,fontWeight:'700'}}>
                    <div className='mousehover' onClick={handleSelect(SECTION_TYPE.VIDEOURL)} style={{width:25,height:25,borderRadius:99,backgroundColor:"#D9D9D9",display:'flex',justifyContent:'center',alignItems:'center',margin:'12px 0px'}}>
                        {type===SECTION_TYPE.VIDEOURL&&<div style={{width:15,height:15,borderRadius:99,backgroundColor:"#6366F1"}}/>}
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;視頻網址
                </div>


                 {/*Buttons*/}
                <div style={{width:'100%',boxSizing:"border-box",display:'flex',flexDirection:'row',justifyContent:'flex-end',marginTop:70}}>
                    <div
                        className='mousehover'
                        onClick={handleCancel}
                        style={{padding:'10px 16px',borderRadius:6,color:'#404040',border:'1px solid #E5E5E5',margin:"0px 20px",userSelect: 'none'}}
                    >
                        取消
                    </div>
                    <div
                        className='mousehover'
                        onClick={handleConfirm}
                        style={{padding:'10px 16px',backgroundColor:'#6366F1',borderRadius:6,color:'white',border:'1px solid #6366F1',userSelect: 'none'}}
                    >
                        確認
                    </div>
                </div>
                {/*End of Buttons*/}

                <div className='mousehover' onClick={handleCancel} style={{position:'absolute',top:10,right:15}}>
                    <CrossSvg length={17} color="#737373"/>
                </div>
            </div>
        </div>
    );
}

export default connect((store)=>{
    return{
      token:store.tokenReducer,
    }
})(Component)