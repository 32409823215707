import React, {useState,useEffect} from 'react';
import { connect } from 'react-redux';
import './WordsListPage.css';
import { useNavigate } from "react-router-dom";
import { fetchWordList, deleteWordsByIds } from '../services/request.service';
import {formatTime,formatDate}  from '../util/utils'

import LoadingPage from './components/LoadingPage';
import SearchIcon from '../svg/SearchSvg';
import FilterIcon from '../svg/FilterSvg';
import DeleteIcon from '../svg/DeleteSvg';
import AddIcon from '../svg/AddSvg';
import CheckIcon from '../svg/TickSvg';
import DownArrowSvg from '../svg/DownArrowSvg';
import PageLeftArrow from '../svg/PageLeftArrowSvg';
import PageRightArrow from '../svg/PageRightArrowSvg';
import DotDotDot from '../svg/DotDotDotSvg';
import AddWordPopup from './components/AddWordPopup';
import DeletePopup from './components/DeletePopup';
import { SECTION_TYPE } from '../dto/section.dto';

const HEADER_LIST=[
    {name:"字卡",field:'text',flex:1},
    {name:"筆劃",flex:1,field:'strokes'},
    {name:"內容",flex:1,field:SECTION_TYPE.CONTENT},
    {name:"字卡圖片",flex:1,field:SECTION_TYPE.MEDIA},
    {name:"筆劃動畫",flex:1,field:'animations'},
    {name:"二維碼",flex:1,field:'qrcode'},
    {name:"最後更新",field:"modifyDate",flex:2},
    {name:"更新",flex:2,field:'modifiedBy'},
]
const SIZES=[
    10,20,50,100
]
function Component(props) {
  const navigate = useNavigate();
  const [isLoading,setisLoading]=useState(false);
  const [page,setpage]=useState(1);
  const [totalPage,settotalPage]=useState(10);
  const [gotoPage,setgotoPage]=useState('');
  const [pageSize,setpageSize]=useState(SIZES[0]);
  const [sort,setsort]=useState({field:'text',order:1});
  const [totalCount,settotalCount]=useState(0);
  const [search,setSearch]=useState('')

  const handleSelectPage=(p)=>()=>{handleSetPage(p)}
  const handleNextPage=()=>{if(page+1<=totalPage)handleSetPage(page+1)}
  const handlePreviousPage=()=>{if(page-1>=1)handleSetPage(page-1)}
  const handleSetPage=(p)=>{
    setpage(p)
    fetchList(pageSize,(p-1)*pageSize)
  }
  const handleGotoPageInput=(e)=>{setgotoPage(e.target.value)}
  const handleGotoPageKeyDown=(e)=>{
    if (e.keyCode === 13){
        let newPage=parseInt(gotoPage,10)
        if(newPage!==NaN&&newPage<=totalPage&&newPage>0){
            handleSetPage(newPage)
            setgotoPage('')
        }else{
            setgotoPage('')
        }
    }
  }
  const handleSetPageSize=(s)=>()=>{
    setpageSize(s)
    setpage(1)
    fetchList(s,0)
  }
  const handleViewItem=(item,index)=>()=>{
    navigate(`/words/details/${item._id}`);
  }

  //fetch list
  const [list,setlist]=useState([])
  const fetchList=async (limit=pageSize,skip=(page-1)*pageSize,sortBy=sort,keyword=search)=>{
    if(isLoading)return
    let filter={}
    let option={
        limit,
        skip,
        sort: {[sortBy.field]:sortBy.order}
    }
    if(keyword){
        filter.text={$in:keyword.split('')}
    }
    setisLoading(true)
    let result = await fetchWordList(props.token.token,filter,option)
    if(result.result){
        setlist(result.result)
        settotalCount(result.count||0)
        settotalPage(result.count>0?Math.ceil(result.count/limit):1)
    }else{
        alert('Fetch data error')
    }
    setisLoading(false)
  }
  useEffect(()=>{
    fetchList()
  },[])

  const handleSelectItem=(item,index)=>()=>{
    let L = [...list]
    L[index].isSelected=!L[index].isSelected
    setlist(L)
  }

  //add word
  const [isAddingWords,setisAddingWords]=useState(false)
  const handleStartAddingWord=()=>{setisAddingWords(true)}
  const handleCancelAddingWord=(refresh=false)=>{
    if(refresh)
        fetchList()
    setisAddingWords(false)
  }
  const handleSortItem=(item,index)=>()=>{
    if(['text','modifyDate','modifiedBy','strokes'].includes(item.field)){
        let s= {...sort};
        if(sort.field===item.field){
            s.order=s.order<0?1:-1;
        }else{
            s={field:item.field,order:1}
        }
        setpage(1)
        setsort(s)
        fetchList(pageSize,0,s)
    }
  }
  const [isDeleting,setisDeleting]=useState(false)
  const handleStartDelete=()=>{
    if(!list.some(i=>i.isSelected)||isLoading){return}
    setisDeleting(true)
  }
  const handleCancelDelete=()=>{setisDeleting(false)}
  const handleDeleteItems=async ()=>{
    if(!list.some(i=>i.isSelected)||isLoading){
        return
    }
    let ids=list.filter(w=>w.isSelected).map(w=>(w._id))
    setisLoading(true)
    let result = await deleteWordsByIds(props.token.token,ids)
    setpage(1);
    fetchList(pageSize,0);
    setisLoading(false)
    setisDeleting(false)
  }
  const handleKeyDown=(e)=>{
    if (e.key === 'Enter') {
        setSearch(e.target.value)
        setpage(1)
        fetchList(pageSize,0,sort,e.target.value)
    }
  }
  return (
    <div className='wordslist_root_'>
        <div className='wordslist_root'>
            <div className='common_title'>字卡表</div>

            {/* search and buttons bar */}
            <div className='wordslist_header'>
                <div className='wordslist_header_left'>
                    <div className='wordslist_searchcontainer'>
                        <SearchIcon/>
                        <input onKeyDown={handleKeyDown} className='wordslist_searchtxt' placeholder='搜尋'/>
                    </div>
                    {/* <div className='wordslist_filterbutton mousehover'>
                        <FilterIcon/>
                    </div> */}
                </div>
                <div className='wordslist_header_right'>
                    <div className='wordslist_deletebutton mousehover' onClick={handleStartDelete} style={{backgroundColor:(list.some(i=>i.isSelected))?"#ED6060":"#EAEAEA"}}>
                        <DeleteIcon/>
                        刪除
                    </div>
                    <div className='wordslist_addbutton mousehover' onClick={handleStartAddingWord}>
                        <AddIcon/>
                        新增字卡
                    </div>
                </div>
            </div>
            {/* end of search and buttons bar */}

            {/* main scrollable list*/}
            <div className='wordslist_maincontainer'>
                {/*header*/}
                <div className='wordslist_table_header'>
                    <div style={{flex:.5}}>
                    </div>
                    {HEADER_LIST.map((item,index)=>
                        <div 
                            key={index}
                            className='wordslist_table_header_unselected mousehover'
                            style={{flex:item.flex||1,color:item?.field===sort.field?"#6366F1":"#737373"}}
                            onClick={handleSortItem(item,index)}
                        >
                            {item.name}
                        </div>
                    )}
                    <div style={{flex:1}}>
                    </div>
                </div>

                {/*body*/}
                <div className='wordslist_table_body_'>
                    <div className='wordslist_table_body'>
                        {list.map((data,dataIndex)=>
                            <div key={dataIndex} className='wordslist_table_row' style={data.isSelected?{background:"#E7E7E7"}:{}}>
                                <div className='wordslist_table_column' style={{flex:.5}} onClick={handleSelectItem(data,dataIndex)}>
                                    <div className={data.isSelected?'wordslist_check_box_selected mousehover':'wordslist_check_box_unselected mousehover'}/>
                                </div>
                                {HEADER_LIST.map((item,index)=>
                                    item?.field==='text'
                                    ?<div key={index} className='wordslist_table_header_unselected' style={{flex:item.flex||1}}>
                                        <div className='wordlist_table_word'>{list[dataIndex]?.text||''}</div>
                                    </div>
                                    :item?.field==='modifyDate'
                                    ?<div key={index} className='wordslist_table_header_unselected' style={{flex:item.flex||1}}>
                                        <div className='wordlist_table_date'>{formatDate(new Date(list[dataIndex]?.modifyDate))} {formatTime(new Date(list[dataIndex]?.modifyDate))}</div>
                                    </div>
                                    :item?.field==='strokes'
                                    ?<div key={index} className='wordslist_table_header_unselected' style={{flex:item.flex||1}}>
                                        <div className='wordlist_table_date' style={{fontWeight:600}}>{list[dataIndex]?.strokes||0}</div>
                                    </div>
                                    :item?.field==='modifiedBy'
                                    ?<div key={index} className='wordslist_table_header_unselected' style={{flex:item.flex||1}}>
                                        <div className='wordlist_table_date'>{list[dataIndex]?.updatedBy}</div>
                                    </div>
                                    :<div key={index} className='wordslist_table_header_unselected' style={{flex:item.flex||1}}>
                                        <div className='wordslist_table_check_container'>
                                            {
                                                item?.field===SECTION_TYPE.CONTENT
                                                ?(data?.sections?.some(d=>(d.type===SECTION_TYPE.CONTENT || (d.type===SECTION_TYPE.WORDCARD&&d?.cards?.length>0) ))?<CheckIcon/>:<div/>)
                                                :item?.field==='animations'
                                                ?((data?.animations?.length>0)?<CheckIcon/>:<div/>)
                                                :item?.field===SECTION_TYPE.MEDIA
                                                ?(data?.sections?.some(d=>(d.type===SECTION_TYPE.MEDIA&&d?.files?.length>0))?<CheckIcon/>:<div/>)
                                                :item?.field==='qrcode'
                                                ?<CheckIcon/>
                                                :<div/>
                                            }
                                        </div>
                                    </div>
                                )}
                                <div className='wordslist_table_column'>
                                    <div className='wordslist_table_viewbutton mousehover' onClick={handleViewItem(data,dataIndex)}>
                                        View
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/*footer*/}
                <div className='wordslist_table_footer'>
                    共 {totalCount} 項目&nbsp;
                    <div className='wordslist_table_footer_pageselection'>
                        <div onClick={handlePreviousPage} className='wordslist_table_footer_pageleftarrow mousehover'>
                            <PageLeftArrow/>
                        </div>
                        {page===1||<div onClick={handleSelectPage(1)} className={'wordslist_table_footer_page_unselected mousehover'}>
                            1
                        </div>}
                        {page>5
                            ?<div className='wordslist_table_footer_page_unselected mousehover'>
                                <DotDotDot/>
                            </div>
                            :totalPage>1&&page>2
                            ?<div onClick={handleSelectPage(2)} className={`wordslist_table_footer_page_unselected mousehover`}>
                                2
                            </div>
                            :<div/>
                        }
                        {page>4&&<div onClick={handleSelectPage(page-2)} className='wordslist_table_footer_page_unselected mousehover'>
                            {page-2}
                        </div>}
                        {page>3&&<div onClick={handleSelectPage(page-1)} className='wordslist_table_footer_page_unselected mousehover'>
                            {page-1}
                        </div>}
                        <div onClick={handleSelectPage(page)} className='wordslist_table_footer_page_selected mousehover'>
                            {page}
                        </div>
                        {totalPage-1>page&&<div onClick={handleSelectPage(page+1)} className='wordslist_table_footer_page_unselected mousehover'>
                            {page+1}
                        </div>}
                        {totalPage-2>page&&<div onClick={handleSelectPage(page+2)} className='wordslist_table_footer_page_unselected mousehover'>
                            {page+2}
                        </div>}
                        {totalPage-4>page
                            ?<div className='wordslist_table_footer_page_unselected mousehover'>
                                <DotDotDot/>
                            </div>
                            :totalPage-3>page
                            ?<div onClick={handleSelectPage(page+3)} className='wordslist_table_footer_page_unselected mousehover'>
                                {page+3}
                            </div>
                            :<div/>
                        }
                        {page!==totalPage&&<div onClick={handleSelectPage(totalPage)} className='wordslist_table_footer_page_unselected mousehover'>
                            {totalPage}
                        </div>}
                        <div onClick={handleNextPage} className='wordslist_table_footer_pagerightarrow mousehover'>
                            <PageRightArrow/>
                        </div>
                    </div>
                    <div className='wordslist_table_footer_sizedropdown' >
                        <div className='mousehover'>{pageSize} / 頁&nbsp;&nbsp;</div>
                        <div className='mousehover'><DownArrowSvg/></div>
                        <div className='wordslist_table_footer_size_boxshadow'/>
                        <div className='wordslist_table_footer_size'>
                            {SIZES.map((item,index)=><div key={index} className='wordslist_table_footer_sizeoption mousehover' onClick={handleSetPageSize(item)}>
                                {item}
                            </div>)}
                        </div>
                    </div>
                    前往&nbsp;
                    <input className='wordslist_table_footer_pageinput' value={gotoPage} onChange={handleGotoPageInput} onKeyDown={handleGotoPageKeyDown} />
                </div>
            </div>
        </div>
        {isDeleting&&<DeletePopup onCancel={handleCancelDelete} onConfirm={handleDeleteItems}/>}
        {isAddingWords&&<AddWordPopup handleCancel={handleCancelAddingWord}/>}
        {isLoading&&<LoadingPage/>}
    </div>
  );
}

export default connect((store)=>{
    return{
      token:store.tokenReducer,
    }
  })(Component)
  