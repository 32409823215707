import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 8l1.41 1.41L7 3.83V16h2V3.83l5.58 5.59L16 8 8 0 0 8z"
        fill="#171717"
      />
    </svg>
  )
}

export default SvgComponent
