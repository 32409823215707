import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={11}
      height={7}
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.195.205a.7.7 0 010 .99l-5.6 5.6a.7.7 0 01-.99 0l-2.8-2.8a.7.7 0 11.99-.99L4.1 5.31 9.205.205a.7.7 0 01.99 0z"
        fill="#22C55E"
      />
    </svg>
  )
}

export default SvgComponent
