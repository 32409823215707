import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={28}
      height={23}
      viewBox="0 0 28 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14 17.4l5.6-7h-4.2V.6h-2.8v9.8H8.4l5.6 7z" fill="#000" />
      <path
        d="M25.2 20.2H2.8v-9.8H0v9.8C0 21.744 1.256 23 2.8 23h22.4c1.544 0 2.8-1.256 2.8-2.8v-9.8h-2.8v9.8z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgComponent
