import React, {useState,useCallback, useEffect} from 'react';
import _ from 'lodash';
import DownArrow from '../../svg/DownArrowSvg2';
import UpArrow from '../../svg/UpArrowSvg';
import BinSvg from '../../svg/BinSvg';
import CopySvg from '../../svg/CopySvg';
import PurpleUploadIcon from '../../svg/PurpleUploadIcon';
import FileDragableZone from './UploadFileDragZone';
import FileDropzone from './UploadFileDropZone'

function Component(props){
    const handleUp=()=>{if(props.handleUp)props.handleUp();}
    const handleDown=()=>{if(props.handleDown)props.handleDown();}
    const handleCopy=()=>{if(props.handleCopy)props.handleCopy();}
    const handleDelete=()=>{if(props.handleDelete)props.handleDelete();}

    const onDropFile=useCallback((files)=>{
        for(let newFile of files){
            if(!['image/png','image/jpeg','image/gif'].includes(newFile.type)){
                alert(`File type ${newFile.type} is not allowed`)
                return
            }
            if(newFile.size>1024*1024*2){
                alert(`File size must be within 2 MB`)
                return
            }
        }
        //check if file name already exists
        for(let f of props.data?.sections[props.index]?.files||[]){
            for(let newFile of files){
                if(f.name === newFile.name){
                    alert('File with same filename already exists')
                    return
                }
            }
        }
        if(props.onFileDrop)props.onFileDrop(files)
      },[props.data])
    
    const updateFileList=(oldLayout,newLayout)=>{
        if(props.onFilesOrderUpdate)props.onFilesOrderUpdate(oldLayout,newLayout)
    }

    const handleRemoveFile=(index)=>{
        if(props.onRemoveFile)props.onRemoveFile(index)
    }


	return(
		<div style={{width:'100%',boxSizing:'border-box'}}>
            <div style={{fontWeight:'700',fontSize:14,display:'flex',alignItems:'center',justifyContent:'space-between',flexDirection:'row',color: '#171717',padding: '4px 0px'}}>
                上載多媒體媒案
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div className="mousehover" style={{margin:'0px 7px'}} onClick={handleUp}><UpArrow/></div>
                    <div className="mousehover" style={{margin:'0px 7px'}} onClick={handleDown}><DownArrow/></div>
                    <div className="mousehover" style={{margin:'0px 7px'}} onClick={handleCopy}><CopySvg/></div>
                    <div className="mousehover" style={{margin:'0px 7px'}} onClick={handleDelete}><BinSvg/></div>
                </div>
            </div>

            <div className='worddetail_imagecontainer' style={{height:140}}>
                <div className='worddetail_drag_txt'>drag and drop to re-arrange the order</div>
                <div className='wordedit_draganddropzone_container'>
                    {props.data?.sections[props.index]?.files?.length>0&&
                        <FileDragableZone 
                            data={props.data} 
                            index={props.index}
                            updateFileList={updateFileList} 
                            handleRemoveFile={handleRemoveFile}
                        />
                    }
                </div>
            </div>
            <div className='worddetail_imagecontainer' style={{height:110,backgroundColor:'white',border:'1px solid #E5E5E5'}}>
                <div className='wordedit_draganddropzone_container'>
                    <FileDropzone data={props.data} onDropFile={onDropFile}/>
                </div>
            </div>

            {/* <div className='worddetail_container1_row' style={{marginTop:15}}>
                <div className='wordedit_uploaditembutton mousehover'>
                    <PurpleUploadIcon/>&nbsp;&nbsp;上載多媒體媒案
                </div>
            </div> */}

            <div style={{width:'100%',height:1,backgroundColor:"#AFAFAF",margin:'20px 0px'}}/>
        </div>
	);
}

export default Component 