export default function reducer(state={
	isloading:false,
	isInit:false,
	
	page_index:'',
	language:'en',
	
},action) {
	//const newState = {...state}
	switch(action.type){
		case "setloading":{
			state={...state, 
				isloading:action.payload
			}
			break;
		}
		case "setisinit":{
			state={...state, 
				isInit:action.payload
			}
			break;
		}
		case "Login":{
			state={...state, 
				page_index:''
			}
			break;
		}
		case "setpageindex":{
			state={...state, 
				page_index:action.payload,
			}
			break;
		}
		case "setlanguage":{
			state={...state, 
				language:action.payload,
			}
			break;
		}
		case "ERR":{
			throw new Error("Reducer Error")
		}
		default: {
			;
		}
	}

	return state
}