import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './AccountManagementPage.css';
import { useNavigate } from "react-router-dom";
import { adminChangePassword } from '../services/request.service';
import LoadingPage from './components/LoadingPage';
import MaskIcon from '../svg/PasswordMask'
import { fetchUserList } from '../services/request.service';
import ChangePWPopup from './components/AdminChangePwPopup';

const HEADER_LIST=[
    {name:"電子郵件",field:'username',flex:4},
    {name:"名字",flex:4,field:'name'},
    {name:"訪問權",field:'type',flex:4},
    {name:"密碼",flex:4},
    {name:"操作",flex:2},
]

function Component(props) {
  const navigate = useNavigate();
  const [isLoading,setisLoading]=useState(false);
  const [sort,setsort]=useState({field:'username',order:1});
  const handleSortItem=(item,index)=>()=>{
    if(['username','name','type'].includes(item.field)){
        let s= {...sort};
        if(sort.field===item.field){
            s.order=s.order<0?1:-1;
        }else{
            s={field:item.field,order:1}
        }
        setsort(s)
        fetchList(s)
    }
  }

  const [list,setlist]=useState([])
  const fetchList=async (sortBy=sort)=>{
    if(isLoading)return
    let option={
        sort: {[sortBy.field]:sortBy.order}
    }
    setisLoading(true)
    let result = await fetchUserList(props.token.token,option)
    if(result.result){
        setlist([...result.result])
    }else{
        setlist([])
    }
    setisLoading(false)
  }
  useEffect(()=>{fetchList()},[])

  const [isChangingPW,setisChangingPW]=useState(null)
  const handleStartChangePassword=(item,index)=>()=>{
    setisChangingPW(item)
  }
  const onCancelChangePW=()=>{setisChangingPW(null)}
  const onConfirmChangePW= async(password)=>{
    if(isLoading)return
    setisLoading(true)
    let result = await adminChangePassword(props.token.token,isChangingPW._id,password)
    if(!result.errno){
        setisChangingPW(null);
        alert('Password Updated')
    }else{
        alert(result?.message||'Unknown Error')
    }
    setisLoading(false)
  }

  return (
    <div className='wordslist_root_'>
        <div className='wordslist_root'>
            <div className='common_title' style={{marginTop:20}}>帳號管理</div>

            {/* main scrollable list*/}
            <div className='wordslist_maincontainer'>
                {/*header*/}
                <div className='wordslist_table_header'>
                    {HEADER_LIST.map((item,index)=>
                        <div 
                            key={index}
                            className='wordslist_table_header_unselected mousehover'
                            style={{flex:item.flex||1,color:item?.field===sort.field?"#6366F1":"#737373",justifyContent:'flex-start'}}
                            onClick={handleSortItem(item,index)}
                        >
                            {item.name}
                        </div>
                    )}
                </div>
                {/*body*/}
                <div className='wordslist_table_body_'>
                    <div className='wordslist_table_body'>
                        {list.map((data,dataIndex)=>
                            <div key={dataIndex} className='wordslist_table_row' style={data.isSelected?{background:"#E7E7E7"}:{}}>
                                {HEADER_LIST.map((item,index)=>
                                    item.name==='密碼'?
                                    <div key={index} className='wordslist_table_header_unselected' style={{flex:item.flex||1,justifyContent:'flex-start'}}>
                                        <div className='wordlist_table_word'>********</div>
                                    </div>
                                    :item.name==='操作'?
                                    <div key={index} className='wordslist_table_header_unselected' style={{flex:item.flex||1,justifyContent:'flex-start'}}>
                                        <div className='wordlist_table_date mousehover' onClick={handleStartChangePassword(data,dataIndex)} style={{color:'#6366F1',height:40,display:'flex',alignItems:'center'}}>重設密碼</div>
                                    </div>
                                    :<div key={index} className='wordslist_table_header_unselected' style={{flex:item.flex||1,justifyContent:'flex-start'}}>
                                        <div className='wordlist_table_date'>{data[item.field]||'-'}</div>
                                    </div>
                                )}
                            </div>
                        )}
                        {list.length===0&&<div>No access Right</div>}
                    </div>
                </div>
            </div>





            
        </div>
        {isChangingPW&&<ChangePWPopup handleConfirm={onConfirmChangePW} handleCancel={onCancelChangePW}/>}
        {isLoading&&<LoadingPage/>}
    </div>
  );
}

export default connect((store)=>{
    return{
      token:store.tokenReducer,
    }
  })(Component)
  