import React, {useState,useCallback, useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import './WordsEditPage.css'
import AddSvg from '../svg/AddSvg';
import '../../node_modules/react-grid-layout/css/styles.css';
import '../../node_modules/react-resizable/css/styles.css';
import _ from 'lodash';
import {fetchResourcesById, updateResourceById, uploadPhoto ,insertResource } from '../services/request.service';
import AddSectionPopup from './components/AddSectionPopup';
import {SECTION_TYPE} from '../dto/section.dto';
import EditContentSection from './components/EditContentSection';
import EditVideoUrlSection from './components/EditVideoUrlSection';
import EditMediaSection from './components/EditMediaSection';
import LoadingPage from './components/LoadingPage';
import {server_ip} from '../config';
import {QRCodeCanvas} from 'qrcode.react';
import DownloadSvg from '../svg/DownloadSvg';

function Component(props) {
  const navigate = useNavigate();
  let { id } = useParams();
  const [data,setdata] = useState({})
  const [isLoading,setisLoading]=useState(false)
  const fetchData=async(force=false)=>{
    if(isLoading&&!force)return
    if(id==='new') {
        setdata({})
        return
    }
    setisLoading(true)
    let result = await fetchResourcesById(props.token.token,id);
    if(result.result){
        setdata(result.result)
    }else{
        alert('Fetch data error')
    }
    setisLoading(false)
  }
  useEffect(()=>{
    fetchData();
  },[id])


  const [isAddingSection,setisAddingSection]=useState(false);
  const handleStartAddSection=()=>{setisAddingSection(true)};
  const handleCancelAddSection=()=>{setisAddingSection(false)};
  const handleConfirmAddSection=(type)=>{
    let d = {...data}
    let sections = d.sections||[];
    sections.push({ type })
    d.sections = sections
    setdata(d)
    setisAddingSection(false);
  }
  const handleTitleChange=(item,index)=>(e)=>{
    let d = {...data}
    d.sections[index].title=e.target.value;
    setdata(d)
  }
  const handleContentChange=(item,index)=>(e)=>{
    let d = {...data}
    d.sections[index].content=e.target.value;
    setdata(d)
  }
  const handleSectionUp=(item,index)=>()=>{
    if(index===0)return
    let d = {...data}
    let sections = d.sections;
    let prev = sections[index-1]
    sections[index-1]=sections[index]
    sections[index]=prev
    d.sections=sections;
    setdata(d)
  }
  const handleSectionDown=(item,index)=>()=>{
    if(index===data.sections.length-1)return
    let d = {...data}
    let sections = d.sections;
    let next = sections[index+1]
    sections[index+1]=sections[index]
    sections[index]=next
    d.sections=sections;
    setdata(d)
  }
  const handleSectionDelete =(item,index)=>()=>{
    let d = {...data}
    let sections = d.sections;
    sections.splice(index, 1);
    d.sections=sections;
    setdata(d)
  }
  const handleSectionCopy = (item,index)=>()=>{
    let d = {...data}
    d.sections.push(JSON.parse(JSON.stringify(d.sections[index])))
    setdata(d)
  }
  const handleFileDrop =(item,index)=> (files)=>{
    let d={...data}
    d.sections[index].files=[...(d.sections[index].files||[]),...files]
    setdata(d)
    console.log(files)
  }
  const handleRemoveFile = (item,index)=> (fileIndex)=>{
    let d = {...data}
    let files = d.sections[index].files;
    files.splice(fileIndex, 1);
    d.sections[index].files=files;
    setdata(d)
  }
  const handleFilesOrder=(item,index)=>(oldLayout,newLayout)=>{
    let array=[]
    newLayout.sort((a,b)=>{if(a.x<b.x){return -1;}else if(b.x<a.x){return 1;}return 0;})
    for(let i of newLayout){
        let oldIndex = _.findIndex(oldLayout,{'i':i.i});
        array.push(data.sections[index].files[oldIndex]);
    }
    let d={...data};
    d.sections[index].files=array;
    setdata(d);
  }

  const handleReset=()=>{fetchData(true);}
  const handleCancel=()=>{navigate(`/resources`);}
  const handleConfirm=async()=>{
    if(isLoading)return
    setisLoading(true)

    //handle upload section files
    for(let section_index in data.sections||[]){
        let section = data.sections[section_index]
        if(section.type===SECTION_TYPE.MEDIA){
            for(let file_index in section.files||[]){
                let file = section.files[file_index]
                if(!file.uploadDate){
                    var formData = new FormData();
                    const newFilename =`${id}_${Date.now()}_${Math.floor(Math.random() * 100)}.${file.type.split('/')[1]}`; 
                    formData.append('json', JSON.stringify({filename:newFilename}));
                    formData.append('file', file);
                    let uploadResult = await uploadPhoto(props.token.token,formData)
                    if(uploadResult.errno){
                        alert('upload error')
                        setisLoading(false);
                        return
                    }
                    data.sections[section_index].files[file_index]= {
                        filename: newFilename,
                        originalFilename: file.name,
                        size: file.size,
                        type: file.type,
                        uploadDate: new Date()
                    }

                }
            }
        }
    }
    const updateData={
        title: data.title|| '',
        sections: data.sections||[],
    }
    let result = id==='new'
        ?await insertResource(props.token.token,updateData)
        :await updateResourceById(props.token.token,id,updateData);
    if(result.result){
        navigate(`/resources`);
    }else{
        alert('Update error');
        setisLoading(false);
    }
  }
  const handleDownloadQrCode=()=>{
    const canvas = document.querySelector('.HpQrcode > canvas')
    var element = document.createElement('a');
    element.href = canvas.toDataURL();
    element.download = `${data.text}_qrcode.png`;
    document.body.appendChild(element);
    element.click();
  }
  const onEditTitle =(e)=>{
    let d={...data};
    d.title=e.target.value
    setdata(d);
  }

  const handleEditVideoUrl =(item,index)=>(e)=>{
    let d = {...data}
    d.sections[index].url=e.target.value;
    setdata(d)
  }



  return (
    <div className='wordslist_root_'>
        <div className='wordslist_root' style={{padding: '0px min(312px,20vw)'}}>
            <div className='row_spacebetween'>
                <div className='common_title'>
                    新增資源
                </div>
            </div>

            <div className='worddetail_main'>
                <div className='worddetail_left'>
                   
                    <div style={{width:"100%",height:20}}/>

                    <div style={{fontWeight:'700',fontSize:14,display:'flex',alignItems:'center',justifyContent:'space-between',flexDirection:'row',color: '#171717',padding: '4px 0px'}}>
                        標題
                    </div>
                    <div className='worddetail_container1_row'>
                        <input
                            placeholder="資源標題"
                            onChange={onEditTitle}
                            maxLength={50}
                            value={data?.title||''}
                            style={{width:'100%',boxSizing:'border-box',padding:'8px 12px 8px 12px',borderRadius:6,border:"1px solid #E5E5E5"}}
                        />
                    </div>
                    
                    {data?.sections?.map((item,index)=>
                        item.type===SECTION_TYPE.CONTENT
                        ?<EditContentSection 
                            isResourceMode={true}
                            key={index}
                            data={item}
                            onEditTitle={handleTitleChange(item,index)}
                            onEditContent={handleContentChange(item,index)}
                            handleUp={handleSectionUp(item,index)}
                            handleDown={handleSectionDown(item,index)}
                            handleDelete={handleSectionDelete(item,index)}
                            handleCopy={handleSectionCopy(item,index)}
                        />
                        :item.type===SECTION_TYPE.MEDIA
                        ?<EditMediaSection
                            key={index}
                            data={data}
                            index={index}
                            handleUp={handleSectionUp(item,index)}
                            handleDown={handleSectionDown(item,index)}
                            handleDelete={handleSectionDelete(item,index)}
                            handleCopy={handleSectionCopy(item,index)}
                            onFileDrop={handleFileDrop(item,index)}
                            onRemoveFile={handleRemoveFile(item,index)}
                            onFilesOrderUpdate={handleFilesOrder(item,index)}
                        />
                        :item.type===SECTION_TYPE.VIDEOURL
                        ?<EditVideoUrlSection
                            key={index}
                            data={item}
                            onEditTitle={handleEditVideoUrl(item,index)}
                            handleUp={handleSectionUp(item,index)}
                            handleDown={handleSectionDown(item,index)}
                            handleDelete={handleSectionDelete(item,index)}
                            handleCopy={handleSectionCopy(item,index)}
                        />
                        :<div key={index}>
                            {item.type}
                        </div>
                    )}
                    
                    <div
                        className='mousehover'
                        onClick={handleStartAddSection}
                        style={{backgroundColor:"#FBBF24",width:177,height:36,borderRadius:6,marginTop:50,marginBottom:50,color: '#FFFFFF',fontSize:12,fontWeight:'700',display:'flex',justifyContent:'center',alignItems:"center"}
                    }>
                        <AddSvg/>&nbsp;&nbsp;增加欄目
                    </div>

                    {id==='new'||<div className='worddetail_subtitle'>
                        QR code
                    </div>}
                    {id==='new'||<div className='HpQrcode worddetail_imagecontainer' style={{position:'relative'}}>
                        <QRCodeCanvas value={`SENR:${id}`} />
                        <div className='mousehover' style={{position:'absolute',bottom:5,right:5}} onClick={handleDownloadQrCode}>
                            <DownloadSvg/>
                        </div>
                    </div>}
                </div>

            </div>
            {/*Buttons*/}
            <div style={{width:'100%',boxSizing:"border-box",display:'flex',flexDirection:'row',justifyContent:'center',marginTop:50,marginBottom:50}}>
                <div
                    className='mousehover'
                    onClick={handleReset}
                    style={{padding:'10px 16px',borderRadius:6,color:'#EF4444',border:'1px solid #EF4444',userSelect: 'none'}}
                >
                    重新填寫
                </div>
                <div
                    className='mousehover'
                    onClick={handleCancel}
                    style={{padding:'10px 16px',borderRadius:6,color:'#404040',border:'1px solid #E5E5E5',margin:"0px 20px",userSelect: 'none'}}
                >
                    取消
                </div>
                <div
                    className='mousehover'
                    onClick={handleConfirm}
                    style={{padding:'10px 16px',backgroundColor:'#6366F1',borderRadius:6,color:'white',border:'1px solid #6366F1',userSelect: 'none'}}
                >
                    確認
                </div>
            </div>
            {/*End of Buttons*/}
        </div>
        {isAddingSection&&<AddSectionPopup noWordCard={true} handleCancel={handleCancelAddSection} handleConfirm={handleConfirmAddSection}/>}
        {isLoading&&<LoadingPage/>}
    </div>
  );
}

export default connect((store)=>{
    return{
      token:store.tokenReducer,
    }
  })(Component)
  