import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './WordsListPage.css';
import { useNavigate } from "react-router-dom";
import { changePassword } from '../services/request.service';
import LoadingPage from './components/LoadingPage';
import MaskIcon from '../svg/PasswordMask'
const cred_max_length=60//todo update this number later
var CapsLock=function(){var e=/Mac/.test(navigator.platform),b=!1,d=[];window.addEventListener("keypress",function(a){var c=a.charCode;a=a.shiftKey;var f=b;97<=c&&122>=c?b=a:!(65<=c&&90>=c)||a&&e||(b=!a);b!==f&&d.forEach(function(a){return a(b)})});return{isOn:function(){return b},addListener:function(a){d.push(a)}}}();

function Component(props) {
  const navigate = useNavigate();
  const [isLoading,setisLoading]=useState(false);
  const [issecure,setissecure] = useState(true);
  const [password, setPassword] = useState('');
  const [password_err, setPassword_err] = useState('');
  const [newpassword, setnewPassword] = useState('');
  const [newpassword_err, setnewPassword_err] = useState('');
  const [confirmnewpassword, setconfirmnewPassword] = useState('');
  const [confirmnewpassword_err, setconfirmnewPassword_err] = useState('');

  const keyPressPWD=(e)=>{if (e.keyCode === 13) { 
    handleConfirm()
  }}
  const handlePwd = (e)=>{
	if (CapsLock.isOn()) {
      setPassword_err('CapsLock is on!')
    } else {
	  setPassword_err('')
    }
	setPassword(e.target.value)
  }
  const handlenewPwd = (e)=>{
	if (CapsLock.isOn()) {
        setnewPassword_err('CapsLock is on!')
    } else {
        setnewPassword_err('')
    }
	setnewPassword(e.target.value)
  }
  const handleConfirmNewPwd = (e)=>{
	if (CapsLock.isOn()) {
        setconfirmnewPassword_err('CapsLock is on!')
    } else {
        setconfirmnewPassword_err('')
    }
	setconfirmnewPassword(e.target.value)
  }

  const handleCancel=()=>{navigate(`/words`);}
  const handleConfirm=async ()=>{
    let isErr=false
    if(password.length<5||password===''||password.length>cred_max_length){
        setPassword_err('密碼不正確，請重新輸入');
        isErr=true
    }else setPassword_err('')
    if(newpassword.length<5||newpassword===''||newpassword.length>cred_max_length){
        setnewPassword_err('密碼長度必須至少為 5');
        isErr=true
    }else setnewPassword_err('')
    if(newpassword!==confirmnewpassword){
        setconfirmnewPassword_err('2組密碼不附合，請重新輸入');
        isErr=true
    }else if(newpassword===password){
        setconfirmnewPassword_err('新密碼不能與舊密碼相同');
        isErr=true
    }else setconfirmnewPassword_err('')
    if(isErr)return

    let response =await changePassword(props.token.token,newpassword,password);
    if(response.errno===20004){
        setPassword_err('密碼不正確，請重新輸入');
        return
    }else if(response.errno){
        setconfirmnewPassword_err(JSON.stringify(response.message));
        return
    }
    handleCancel()
    alert('密碼已更新')
  }
  const handleToggleMask=()=>{setissecure(!issecure)}
  return (
    <div className='wordslist_root_'>
        <div className='wordslist_root'>
            <div className='common_title' style={{marginTop:20}}>更改密碼</div>

            <div className='login_subtitle' style={{marginTop:50}}>現在密碼*</div>
            <div className='login_password'>
                <input
                    value={password}
                    onChange={handlePwd}
                    onKeyDown={keyPressPWD}
                    className='login_input'
                    maxLength={cred_max_length}
                    placeholder='密碼'
                    type={issecure?"password":"text"}
                    style={password_err?{border:"1px solid #FCA5A5"}:{}}
                />
                <div className='login_mask_icon mousehover'>
                    <div onClick={handleToggleMask} style={{opacity:issecure?.2:1}}>
                        <MaskIcon/>
                    </div>
                </div>
            </div>
            <div className='login_error'>{password_err}</div>

            <div className='login_subtitle' style={{marginTop:50}}>新的密碼*</div>
            <div className='login_password'>
                <input
                    value={newpassword}
                    onChange={handlenewPwd}
                    onKeyDown={keyPressPWD}
                    maxLength={cred_max_length}
                    className='login_input'
                    placeholder='密碼'
                    type={issecure?"password":"text"}
                    style={newpassword_err?{border:"1px solid #FCA5A5"}:{}}
                />
                <div className='login_mask_icon mousehover'>
                    <div onClick={handleToggleMask} style={{opacity:issecure?.2:1}}>
                        <MaskIcon/>
                    </div>
                </div>
            </div>
            <div className='login_error'>{newpassword_err}</div>

            <div className='login_subtitle' style={{marginTop:50}}>確認新的密碼*</div>
            <div className='login_password'>
                <input
                    value={confirmnewpassword}
                    onChange={handleConfirmNewPwd}
                    onKeyDown={keyPressPWD}
                    maxLength={cred_max_length}
                    className='login_input'
                    placeholder='密碼'
                    type={issecure?"password":"text"}
                    style={confirmnewpassword_err?{border:"1px solid #FCA5A5"}:{}}
                />
                <div className='login_mask_icon mousehover'>
                    <div onClick={handleToggleMask} style={{opacity:issecure?.2:1}}>
                        <MaskIcon/>
                    </div>
                </div>
            </div>
            <div className='login_error'>{confirmnewpassword_err}</div>


            {/*Buttons*/}
            <div style={{width:'100%',boxSizing:"border-box",display:'flex',flexDirection:'row',justifyContent:'flex-end',marginTop:50,marginBottom:50}}>
                <div
                    className='mousehover'
                    onClick={handleCancel}
                    style={{padding:'10px 16px',borderRadius:6,color:'#404040',border:'1px solid #E5E5E5',margin:"0px 20px",userSelect: 'none'}}
                >
                    取消
                </div>
                <div
                    className='mousehover'
                    onClick={handleConfirm}
                    style={{padding:'10px 16px',backgroundColor:'#6366F1',borderRadius:6,color:'white',border:'1px solid #6366F1',userSelect: 'none'}}
                >
                    確認
                </div>
            </div>
            {/*End of Buttons*/}
            
        </div>
        {isLoading&&<LoadingPage/>}
    </div>
  );
}

export default connect((store)=>{
    return{
      token:store.tokenReducer,
    }
  })(Component)
  