export default function reducer(state={
	isMsg:false,
	errMsg:'Error',
},action) {
	//const newState = {...state}
	switch(action.type){
		case 'setMessage':{
			state={...state, 
				isMsg:action.payload,
				errMsg:action.msg
			}
			break;
		}
		case "ERR":{
			throw new Error("Error Reducer Error")
		}
		default: {
			;
		}
	}
	return state
}