import { combineReducers } from 'redux'

import controlReducer from './controlReducer'
import errorReducer from './errorReducer'
import tokenReducer from './tokenReducer'

export default combineReducers({
	controlReducer,
	errorReducer,
	tokenReducer,
})