import React, {useState} from 'react';
import './MenuBar.css';
import { connect } from 'react-redux';
import ProfileIcon from '../svg/ProfileSvg';
import DownArrow from '../svg/DownArrowSvg';
import { } from '../actions/actions';
import { useCookies } from 'react-cookie';
import {cookies_string} from '../config.js';
import { Login } from '../actions/actions';
import { useNavigate, useLocation } from 'react-router-dom';

function Component(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [ __, _, removeCookie] = useCookies([`@${cookies_string}_cred`,`@${cookies_string}_token`]);
    const [isShowMenu,setisShowMenu]=useState(false)
	const handleLogout=()=>{
		props.dispatch(Login(false))
        removeCookie(`@${cookies_string}_token`,'/')
        removeCookie(`@${cookies_string}_cred`,'/')   
	}
    const toggleMenu=()=>{setisShowMenu(!isShowMenu)}
    const handleRoute=(route)=>()=>{
        navigate(`/${route}`);
    }
    return(
        <div className='menu_root'>
            <div className='menu_container_1'>
                <div className='menu_icon'/>
                <div className={(location.pathname.split('/')[1]==='words')||location.pathname.split('/')[1]===''?'menu_item_selected mousehover':'menu_item_unselected mousehover'} onClick={handleRoute('words')}>字卡表</div>
                <div className={location.pathname.split('/')[1]==='resources'?'menu_item_selected mousehover':'menu_item_unselected mousehover'} onClick={handleRoute('resources')}>其他資源表</div>
            </div>
            <div className='menu_container_1'>
                <ProfileIcon/>
                <div className='menu_username'>{props?.token?.username||'unknown user'}</div>
                <div className='menu_arrowbutton mousehover' onClick={toggleMenu}><DownArrow/></div>
                <div className='menu_logout_button mousehover' onClick={handleLogout}>登出</div>
                <div className='menu_setting'>
                    <div className='menu_setting_item mousehover' onClick={handleRoute('changepassword')}>更改密碼</div>
                    <div className='menu_setting_item mousehover' onClick={handleRoute('manage')}>帳號管理</div>
                </div>
            </div>
        </div>
    )
}
export default connect((store)=>{
    return{
      token:store.tokenReducer,
    }
  })(Component)