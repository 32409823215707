import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={15}
      height={10}
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.377.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.415 0l-4-4a1 1 0 011.415-1.414l3.292 3.293L12.962.293a1 1 0 011.415 0z"
        fill="#737373"
      />
    </svg>
  )
}

export default SvgComponent
