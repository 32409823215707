import React, {useState} from 'react';
import './LoginPage.css';
import MaskIcon from '../svg/PasswordMask';
import {cookies_string} from '../config.js'
import { useCookies } from 'react-cookie';

import { connect } from 'react-redux';
import { Login } from '../actions/actions'

import { loginWithPassword } from '../services/request.service'

const cred_max_length=60//todo update this number later
var CapsLock=function(){var e=/Mac/.test(navigator.platform),b=!1,d=[];window.addEventListener("keypress",function(a){var c=a.charCode;a=a.shiftKey;var f=b;97<=c&&122>=c?b=a:!(65<=c&&90>=c)||a&&e||(b=!a);b!==f&&d.forEach(function(a){return a(b)})});return{isOn:function(){return b},addListener:function(a){d.push(a)}}}();


function isValidEmail(mail) {
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
    return (true)
  }
  return (false)
}


function Component(props) {
  const [issecure,setissecure] = useState(true);
  const [isloading,setisloading]= useState(false);
  const [username, setUserName] = useState('');
  const [username_err,setusername_err] = useState('');
  const [password, setPassword] = useState('');
  const [password_err, setPassword_err] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies([`@${cookies_string}_cred`,`@${cookies_string}_token`]);
  const [isforgetPassword,setisforgetPassword] = useState(false);
  const [isforgetPWSuccess,setisforgetPWSuccess]=useState(false);
  
  const handleLogin=async ()=>{
    let isErr=false
    if(!isValidEmail(username)){
        setusername_err('電郵地址錯誤，請重新輸入');
        isErr=true
    }else setusername_err('')
    if(password.length<5||password===''||password.length>cred_max_length){
        setPassword_err('密碼錯誤，請重新輸入');
        isErr=true
    }else setPassword_err('')
    if(isErr)return

    let response =await loginWithPassword(username,password);
    if(response.errno===20003){
      setPassword_err('密碼錯誤，請重新輸入');
      return
    }else if(response.errno){
      alert(response.messaage||'Unknown error!')
      return
    }else {
      const token=response?.token?.token
      const usertype=response?.token?.type

      props.dispatch(Login(true,token,usertype,username))
      setCookie(`@${cookies_string}_cred`, {username,password,usertype}, { path: '/' });
      setCookie(`@${cookies_string}_token`, token, { path: '/', maxAge:172800});
    }
  }

  const keyPressUSR=(e)=>{if (e.keyCode === 13) { handleLogin(); }}
  const keyPressPWD=(e)=>{if (e.keyCode === 13) { handleLogin(); }}
  const handlePwd = (e)=>{
	if (CapsLock.isOn()) {
      setPassword_err('CapsLock is on!')
    } else {
	  setPassword_err('')
    }
	setPassword(e.target.value)
  }
  const handleToggleMask=()=>{setissecure(!issecure)}

  //forgetpassword
  const handleStartForgetPassword=()=>{setisforgetPassword(true)}
  const handleForgetPassword=()=>{
    let isErr=false
    if(!isValidEmail(username)){
        setusername_err('電郵格式不對，請重新輸入');
        isErr=true
    }else setusername_err('')
    if(isErr)return
    //todo update here add api call
    setisforgetPWSuccess(true)
  }
  const handleConfirmForgetPW=()=>{
    setUserName('')
    setusername_err('')
    setisforgetPWSuccess(false)
    setisforgetPassword(false)
  }
  const keyPressUSRForgetPW=(e)=>{if(e.keyCode === 13){handleForgetPassword()}}

  if(isforgetPWSuccess)
    return(
        <div className='login_root'>
            <div className='login_container'>
                <div className='login_title' style={{marginTop:0}}>完成！</div>
                <div className='login_subtitle' style={{color:"#737373"}}>請檢查電子郵件以重置密碼。</div>
                <div className='login_button mousehover' onClick={handleConfirmForgetPW}>確認</div>
            </div>
        </div>
    )
  if(isforgetPassword)
    return(
        <div className='login_root'>
            <div className='login_container'>
                <div className='login_title' style={{marginTop:0}}>忘記密碼</div>
                <div className='login_subtitle'>電郵地址</div>
                <input 
                    className='login_input'
                    value={username}
                    onChange={(e)=>{setUserName(e.target.value)}}
                    onKeyDown={keyPressUSRForgetPW}
                    placeholder='電郵地址'
                />
                <div className='login_error'>{username_err}</div>
                <div className='login_button mousehover' onClick={handleForgetPassword}>確認</div>
            </div>
        </div>
    )
  return (
    <div className='login_root'>
		<div className='login_container'>
            <div className='login_logo'/>
            <div className='login_title'>登入</div>

            <div className='login_subtitle'>電郵地址</div>
            <input 
                className='login_input'
                value={username}
                onChange={(e)=>{setUserName(e.target.value)}}
                onKeyDown={keyPressUSR}
                placeholder='電郵地址'
            />
            <div className='login_error'>{username_err}</div>

            <div className='login_subtitle'>密碼</div>
            <div className='login_password'>
                <input
                    value={password}
                    onChange={handlePwd}
                    onKeyDown={keyPressPWD}
                    className='login_input'
                    placeholder='密碼'
                    maxLength={cred_max_length}
                    type={issecure?"password":"text"}
                />
                <div className='login_mask_icon mousehover'>
                    <div onClick={handleToggleMask} style={{opacity:issecure?.2:1}}>
                        <MaskIcon/>
                    </div>
                </div>
            </div>
            <div className='login_error'>{password_err}</div>
            <div className='login_forgetpassword_container'>
                <div className='login_forgetpassword mousehover' onClick={handleStartForgetPassword}>
                    忘記密碼？
                </div>
            </div>

            {/* <div className='login_remember_container'>
                <div className='login_remember_box mousehover'/>
                <div className='login_remember_txt'>記住帳號</div>
            </div> */}

            <div className='login_button mousehover' onClick={handleLogin}>登入</div>
        </div>
    </div>
  );
}

export default connect((store)=>{
    return{
      token:store.tokenReducer,
    }
  })(Component)
  