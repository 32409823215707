import React, { useState, useEffect} from 'react';
import {} from '../../services/request.service'
import { connect } from 'react-redux';

import DeletIconSvg from '../../svg/CrossSvg';
import MaskIcon from '../../svg/PasswordMask'
import RedCrossIcon from '../../svg/RedCrossSvg';
import CheckIcon from '../../svg/CheckSvg';

const cred_max_length=60//todo update this number later
var CapsLock=function(){var e=/Mac/.test(navigator.platform),b=!1,d=[];window.addEventListener("keypress",function(a){var c=a.charCode;a=a.shiftKey;var f=b;97<=c&&122>=c?b=a:!(65<=c&&90>=c)||a&&e||(b=!a);b!==f&&d.forEach(function(a){return a(b)})});return{isOn:function(){return b},addListener:function(a){d.push(a)}}}();


function Component(props) {
    const [isloading,setisloading]=useState(false);

    const [issecure,setissecure] = useState(true);
    const [newpassword, setnewPassword] = useState('');
    const [newpassword_err, setnewPassword_err] = useState('');
    const [confirmnewpassword, setconfirmnewPassword] = useState('');
    const [confirmnewpassword_err, setconfirmnewPassword_err] = useState('');

    const handlenewPwd = (e)=>{
        if (CapsLock.isOn()) {
            setnewPassword_err('CapsLock is on!')
        } else {
            setnewPassword_err('')
        }
        setnewPassword(e.target.value)
      }
      const handleConfirmNewPwd = (e)=>{
        if (CapsLock.isOn()) {
            setconfirmnewPassword_err('CapsLock is on!')
        } else {
            setconfirmnewPassword_err('')
        }
        setconfirmnewPassword(e.target.value)
      }


    const handleCancel=()=>{if(props.handleCancel)props.handleCancel()};

    const handleConfirm=async ()=>{
        if(isloading)return
        if(!isLengthGreaterThanFive())return
        else if(!isContainUpperCase())return
        else if(!isContainSpecialChar())return
        else if(!isPasswordMarch())return

        setisloading(true)
        try{
            await props.handleConfirm(newpassword);
        }catch(e){
            console.log(e)
            alert('Error')
            setisloading(false)
        }finally{
        }
    }
    const keyPressPWD=(e)=>{if (e.keyCode === 13) { handleConfirm()}};
    const handleToggleMask=()=>{setissecure(!issecure)};

    const isLengthGreaterThanFive =()=>{return newpassword.length>=5}
    const isContainUpperCase =()=>{return /[A-Z]/.test(newpassword);}
    const isContainSpecialChar=()=>{return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(newpassword);}
    const isPasswordMarch=()=>{return newpassword&&newpassword===confirmnewpassword};

    return (
        <div style={{position:'fixed',top:0,bottom:0,left:0,right:0,backgroundColor: 'rgba(0, 0, 0, 0.5)',display:'flex',justifyContent:"center",alignItems:'center'}}>
            <div style={{padding:'30px 32px',display:'flex',width:'100%',maxWidth:700,backgroundColor:'white',borderRadius:10,flexDirection:'column',alignItems:'flex-start',boxSizing:'border-box',position:'relative'}}>
                {/*Title*/}
                <div style={{color: '#171717',fontWeight:800,fontSize:36,width:"100%",boxSizing:'border-box',textAlign:'left',userSelect: 'none'}}>
                    重設密碼
                </div>
                {/*End of Title*/}

                {/*Body*/}
                <div className='login_subtitle' style={{marginTop:50}}>新的密碼*</div>
                <div className='login_password'>
                    <input
                        value={newpassword}
                        onChange={handlenewPwd}
                        onKeyDown={keyPressPWD}
                        maxLength={cred_max_length}
                        className='login_input'
                        placeholder='密碼'
                        type={issecure?"password":"text"}
                        style={newpassword_err?{border:"1px solid #FCA5A5"}:{}}
                    />
                    <div className='login_mask_icon mousehover'>
                        <div onClick={handleToggleMask} style={{opacity:issecure?.2:1}}>
                            <MaskIcon/>
                        </div>
                    </div>
                </div>
                <div className='login_error'>{newpassword_err}</div>
                <div className='accountmanage_text_1'>
                    {isLengthGreaterThanFive()?<CheckIcon/>:<RedCrossIcon/>}
                    長度最少為 5 個字元
                </div>
                <div className='accountmanage_text_1'>
                    {isContainUpperCase()?<CheckIcon/>:<RedCrossIcon/>}
                    至少一個大寫
                </div>
                <div className='accountmanage_text_1'>
                    {isContainSpecialChar()?<CheckIcon/>:<RedCrossIcon/>}
                    至少有一個特殊字元
                </div>
                <div className='login_subtitle' style={{marginTop:50}}>確認新的密碼*</div>
                <div className='login_password'>
                    <input
                        value={confirmnewpassword}
                        onChange={handleConfirmNewPwd}
                        onKeyDown={keyPressPWD}
                        maxLength={cred_max_length}
                        className='login_input'
                        placeholder='密碼'
                        type={issecure?"password":"text"}
                        style={confirmnewpassword_err?{border:"1px solid #FCA5A5"}:{}}
                    />
                    <div className='login_mask_icon mousehover'>
                        <div onClick={handleToggleMask} style={{opacity:issecure?.2:1}}>
                            <MaskIcon/>
                        </div>
                    </div>
                </div>
                <div className='login_error'>{confirmnewpassword_err}</div>
                <div className='accountmanage_text_1'>
                    {isPasswordMarch()?<CheckIcon/>:<RedCrossIcon/>}               
                    新密碼必須相同
                </div>
                {/*End of Body*/}

                {/*Buttons*/}
                <div style={{width:'100%',boxSizing:"border-box",display:'flex',flexDirection:'row',justifyContent:'flex-end',marginTop:50}}>
                    <div
                        className='mousehover'
                        onClick={handleCancel}
                        style={{padding:'10px 16px',borderRadius:6,color:'#404040',border:'1px solid #E5E5E5',margin:"0px 20px",userSelect: 'none'}}
                    >
                        取消
                    </div>
                    <div
                        className='mousehover'
                        onClick={handleConfirm}
                        style={{padding:'10px 16px',backgroundColor:'#6366F1',borderRadius:6,color:'white',border:'1px solid #6366F1',userSelect: 'none'}}
                    >
                        確認
                    </div>
                </div>
                {/*End of Buttons*/}

                <div className='mousehover' onClick={handleCancel} style={{position:'absolute',top:10,right:15}}>
                    <DeletIconSvg length={17} color="#737373"/>
                </div>
            </div>
        </div>
    );
}

export default connect((store)=>{
    return{
      token:store.tokenReducer,
    }
  })(Component)