import React, { useState, useEffect} from 'react';
import { connect } from 'react-redux';

import LoadingPage from './LoadingPage';
import DeletIconSvg from '../../svg/CrossSvg';


function Component(props) {
    function handleCancel(){
        if(props.onCancel)props.onCancel()
    }
    function handleConfirm(){
        if(props.onConfirm)props.onConfirm()
    }
    return (
        <div style={{position:'fixed',top:0,bottom:0,left:0,right:0,backgroundColor: 'rgba(0, 0, 0, 0.5)',display:'flex',justifyContent:"center",alignItems:'center'}}>
            <div style={{padding:'30px 32px',display:'flex',width:'100%',maxWidth:700,backgroundColor:'white',borderRadius:10,flexDirection:'column',alignItems:'center',boxSizing:'border-box',position:'relative'}}>
                {/*Title*/}
                <div style={{color: '#171717',fontWeight:800,fontSize:36,width:"100%",boxSizing:'border-box',textAlign:'left',userSelect: 'none'}}>
                    確認刪除
                </div>
                {/*End of Title*/}

                {/*Buttons*/}
                <div style={{width:'100%',boxSizing:"border-box",display:'flex',flexDirection:'row',justifyContent:'flex-end',marginTop:50}}>
                    <div
                        className='mousehover'
                        onClick={handleCancel}
                        style={{padding:'10px 16px',borderRadius:6,color:'#404040',border:'1px solid #E5E5E5',margin:"0px 20px",userSelect: 'none'}}
                    >
                        取消
                    </div>
                    <div
                        className='mousehover'
                        onClick={handleConfirm}
                        style={{padding:'10px 16px',backgroundColor:'#6366F1',borderRadius:6,color:'white',border:'1px solid #6366F1',userSelect: 'none'}}
                    >
                        確認
                    </div>
                </div>
                {/*End of Buttons*/}

                <div className='mousehover' onClick={handleCancel} style={{position:'absolute',top:10,right:15}}>
                    <DeletIconSvg length={17} color="#737373"/>
                </div>
            </div>
        </div>
    );
}

export default connect((store)=>{
    return{
      token:store.tokenReducer,
    }
  })(Component)