import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 2.5a1 1 0 011-1h12a1 1 0 011 1v1.697a1 1 0 01-.293.707L9.848 9.763a1 1 0 00-.292.707v1.919L6.444 15.5v-5.03a1 1 0 00-.292-.707l-4.86-4.859A1 1 0 011 4.197V2.5z"
        stroke="#171717"
        strokeWidth={1.67}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgComponent
