import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.515 1.234a.375.375 0 010 .53L4.28 6l4.235 4.235a.375.375 0 11-.53.53l-4.5-4.5a.375.375 0 010-.53l4.5-4.5a.375.375 0 01.53 0z"
        fill="#0D6EFD"
      />
    </svg>
  )
}

export default SvgComponent
