import './App.css';
import "bootstrap/dist/css/bootstrap.min.css"
import { Routes,Route } from 'react-router-dom'
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Login } from './actions/actions'
import { cookies_string } from './config.js'
import { validToken } from './services/request.service'
import { useEffect } from 'react';

import MenuBar from './screens/MenuBar';
import PageNotFound from './screens/PageNotFound'
import LoginPage from './screens/LoginPage'
import WordsListPage from './screens/WordsListPage';
import WordsDetailPage from './screens/WordsDetailPage';
import WordsEditPage from './screens/WordsEditPage';
import ResourcesPage from './screens/ResourcesPage';
import ResourcesEditPage from './screens/ResourcesEditPage';
import ChangePasswordPage from './screens/ChangePasswordPage';
import AccountManagementPage from './screens/AccountManagementPage';

function App(props) {
  const [cookies, setCookie, removeCookie] = useCookies([`@${cookies_string}_cred`,`@${cookies_string}_token`]);
  useEffect(() => {
    async function check_token(){
      let login_cred = cookies[`@${cookies_string}_cred`];
      let token = cookies[`@${cookies_string}_token`];
      if (token && login_cred) {
        let res = await validToken(token)
        if(res.errno===0 && login_cred.username && login_cred.usertype ){
          props.dispatch(Login(true,token,login_cred.usertype,login_cred.username))
        }
      } else {
        removeCookie(`@${cookies_string}_token`,'/')
      }
    }
    check_token()
  }, [])

  if(!props.token.isLogin)
    return(
      <LoginPage/>
    );
  return (
    <div className="root">
      <MenuBar/>
      <Routes>
        <Route path="/" element={<WordsListPage />}/>
        <Route path="/words" element={<WordsListPage />}/>
        <Route path="/words/details/:id" element={<WordsDetailPage />}/>
        <Route path="/words/edit/:id" element={<WordsEditPage />}/>
        <Route path="/resources" element={<ResourcesPage />}/>
        <Route path="/resources/:id" element={<ResourcesEditPage />}/>
        <Route path="/changepassword" element={<ChangePasswordPage />}/>
        <Route path="/manage" element={<AccountManagementPage />}/>
        <Route path="*" element={<PageNotFound />}/>
      </Routes>
    </div>
  );
}

export default connect((store)=>{
  return{
    token:store.tokenReducer,
    control:store.controlReducer,
  }
})(App)

