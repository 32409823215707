import React, {useState,useCallback, useEffect} from 'react';
import DownArrow from '../../svg/DownArrowSvg2';
import UpArrow from '../../svg/UpArrowSvg';
import BinSvg from '../../svg/BinSvg';
import CopySvg from '../../svg/CopySvg';
import RemoveIcon from '../../svg/RemoveIconSvg';
import DragIcon from '../../svg/DragIconSvg';
import PurpleAddIcon from '../../svg/PurpleAddIconSvg';
import RGL, { WidthProvider } from 'react-grid-layout';
import _ from 'lodash';
const ReactGridLayout = WidthProvider(RGL);
const MAXLENGTH = 100;
const convertToLayout=(data)=>{return data?.map((item,index)=>({i:`${index}`,x:0,y:index,w:1,h:1,data:item}))||[]}


function Component(props){
    const handleUp=()=>{if(props.handleUp)props.handleUp();}
    const handleDown=()=>{if(props.handleDown)props.handleDown();}
    const handleCopy=()=>{if(props.handleCopy)props.handleCopy();}
    const handleDelete=()=>{if(props.handleDelete)props.handleDelete();}

    const [layout,setlayout] = useState(convertToLayout(props.data?.sections[props.index]?.cards||[]));
    useEffect(()=>{
        //work around for issue of ReactGridLayout not updating width
        window.dispatchEvent(new Event('resize'));
    },[layout]);
    useEffect(()=>{
        setlayout(convertToLayout(props.data?.sections[props.index]?.cards||[]))
    },[props.data])
    
    const onDragStop=useCallback((layout)=>(layout_, oldItem, newItem)=>{
        const clonelayout=[...layout_]//_.cloneDeep(layout);
        const index=_.findIndex(layout,{'i':oldItem.i})
        let dragY=clonelayout[index].y
        if(layout[index].y<dragY){
            for(let i in layout){
                if(layout[i].y<=dragY&&layout[i].y>layout[index].y)
                    clonelayout[i].y=layout[i].y-1
            }
        }else if(layout[index].y>dragY){
            for(let i in layout){
                if(layout[i].y>=dragY&&layout[i].y<layout[index].y)
                    clonelayout[i].y=layout[i].y+1
            }
        }else return
        clonelayout[index].y=dragY
        setlayout(clonelayout)
        if(props.updateWordCardOrder)props.updateWordCardOrder(layout,clonelayout)
    },[layout])

    const onEditKey=(item,index)=>(e)=>{if(props.onEditKey)props.onEditKey(item,index,e)};
    const onEditValue=(item,index)=>(e)=>{if(props.onEditValue)props.onEditValue(item,index,e)};
    const onAddCard=()=>{if(props.onAddCard)props.onAddCard()}
    const onDelete=(item,index)=>()=>{if(props.onDelete){props.onDelete(item,index)}};

	return(
		<div style={{width:'100%',boxSizing:'border-box'}}>
            <div style={{fontWeight:'700',fontSize:14,display:'flex',alignItems:'center',justifyContent:'space-between',flexDirection:'row',color: '#171717',padding: '4px 0px'}}>
                字
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div className="mousehover" style={{margin:'0px 7px'}} onClick={handleUp}><UpArrow/></div>
                    <div className="mousehover" style={{margin:'0px 7px'}} onClick={handleDown}><DownArrow/></div>
                    <div className="mousehover" style={{margin:'0px 7px'}} onClick={handleCopy}><CopySvg/></div>
                    <div className="mousehover" style={{margin:'0px 7px'}} onClick={handleDelete}><BinSvg/></div>
                </div>
            </div>

            <div className='worddetail_container1'>
                <ReactGridLayout
                    className="layout"
                    layout={layout}
                    cols={1}
                    rowHeight={35}
                    maxRows={layout.length} 
                    preventCollision={true} 
                    allowOverlap={true} 
                    isDraggable={true}
                    isResizable={false}
                    onDragStop={onDragStop(layout)} 
                    draggableHandle=".drag-handle" 
                    style={{height:layout.length*50,margin:0,padding:0}}
                > 
                    {layout?.map((item,index)=>
                        <div key={item.i} className='worddetail_container1_row'>
                            <input 
                                className='worddetail_container1_row_key'
                                value={props.data?.sections[props.index]?.cards[index]?.key||''}
                                onChange={onEditKey(item,index)}
                                maxLength={MAXLENGTH}
                            />
                            <input 
                                className='worddetail_container1_row_value'
                                value={props.data?.sections[props.index]?.cards[index]?.value||''}
                                onChange={onEditValue(item,index)}
                                maxLength={MAXLENGTH}
                            />
                            <div 
                                className='wordedit_removeicon mousehover'
                                onClick={onDelete(item,index)}
                            >
                                <RemoveIcon/>
                            </div>
                            <div className='drag-handle wordedit_dragicon mousehover'>
                                <DragIcon/>
                            </div>
                        </div>
                    )}
                </ReactGridLayout>
                <div className='worddetail_container1_row'>
                    <div className='wordedit_additembutton mousehover' onClick={onAddCard}>
                        <PurpleAddIcon/>&nbsp;&nbsp;增加欄目
                    </div>
                </div>
            </div>

            
            <div style={{width:'100%',height:1,backgroundColor:"#AFAFAF",margin:'20px 0px'}}/>
        </div>
	);
}

export default Component 