export function formatAMPM(date){
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var ampm = hours >= 12 ? 'PM' : 'AM';
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0'+minutes : minutes;
	var strTime = hours + ':' + minutes + '' + ampm;
	return strTime;
}
export function formatTime(date,second=false){
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var seconds = date.getSeconds();
	hours = hours < 10 ? '0'+hours : hours;
	minutes = minutes < 10 ? '0'+minutes : minutes;
	seconds = seconds < 10 ? '0'+seconds : seconds;
	var strTime = second?(hours + ':' + minutes+":"+seconds):(hours + ':' + minutes) 
	return strTime;
}
export function formatDate(date,short=false){
	//const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
	const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
	if(short) return `${date.getDate()}-${months[date.getMonth()]}`
	return `${date.getDate()<10?`0${date.getDate()}`:date.getDate()}/${months[date.getMonth()]}/${date.getFullYear()}`
}
export function formatWeekday(date){
	const weekdays = [ "Sun","Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
	return `${weekdays[date.getDay()]}`
}