export function Log(data,includeTime=true){
	const today = new Date();
	const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
	return{
		type:"Log",
		payload:includeTime?`[${time}] ${data}`:data,
	}
}
export function Login(login,token=null,usertype=null,username=null){
	return{
		type:"Login",
		payload:login,
		token:token,
		usertype:usertype,
		username:username
	}
}
