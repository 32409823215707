import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={32}
      height={34}
      viewBox="0 0 32 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.112 23.152c-3.596 0-6.51-2.802-6.51-6.259 0-2.984 2.172-5.48 5.079-6.108a7.562 7.562 0 01-.197-1.716c0-4.321 3.643-7.824 8.138-7.824 3.937 0 7.221 2.688 7.975 6.26l.162-.001c4.494 0 8.138 3.503 8.138 7.824 0 3.785-2.796 6.943-6.51 7.668m-3.255-4.538l-4.883-4.695m0 0l-4.882 4.695m4.882-4.695v18.778"
        stroke="#171717"
        strokeWidth={1.67}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgComponent
