import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={props.length||8}
      height={props.length||8}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.689 7.51a.64.64 0 01-.906-.906l2.458-2.458L.783 1.688A.64.64 0 011.69.782L4.147 3.24 6.605.782a.64.64 0 01.905.906L5.052 4.146 7.51 6.604a.64.64 0 11-.905.905L4.147 5.051 1.689 7.51z"
        fill={props.color||"#111827"}
      />
    </svg>
  )
}

export default SvgComponent
