import {useDropzone} from 'react-dropzone'
import React, {useCallback} from 'react';
import _ from 'lodash';
import UploadSvg from '../../svg/UploadFileSvg';
export default function FileDropzone(props) {
    const onDrop = useCallback(acceptedFiles => {
      if(props.onDropFile){
        props.onDropFile(acceptedFiles)
      }
    }, [props.data])
    
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  
    return (
      <div {...getRootProps()} style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column'}}>
        <input {...getInputProps()} />
        <UploadSvg/>
        <div style={{fontSize:16,marginTop:5,color:'#171717'}}><b>上傳文件</b>或拖放</div>
        <div style={{fontSize:14,color:'#737373'}}>PNG、JPG、GIF 最大 2MB</div>
        {/*
          isDragActive ?
            <p>Drop the files here ...</p> :
            <p></p>
        */}
      </div>
    )
  }