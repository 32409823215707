import React from 'react';
export default function PageNotFound() {
  return (
    <div className='wordslist_root_'>
      <div className='wordslist_root'>
        Page Not Found
      </div>
    </div>
  );
}

