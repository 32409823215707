import React from "react"
import DownArrow from '../../svg/DownArrowSvg2';
import UpArrow from '../../svg/UpArrowSvg';
import BinSvg from '../../svg/BinSvg';
import CopySvg from '../../svg/CopySvg';
import YoutubeEmbed from './YoutubeEmbed';

const MAXLENGTH=300

function Component(props){
    const handleUp=()=>{if(props.handleUp)props.handleUp();}
    const handleDown=()=>{if(props.handleDown)props.handleDown();}
    const handleCopy=()=>{if(props.handleCopy)props.handleCopy();}
    const handleDelete=()=>{if(props.handleDelete)props.handleDelete();}
    const onEditTitle=(e)=>{if(props.onEditTitle)props.onEditTitle(e);}
	return(
		<div style={{width:'100%',boxSizing:'border-box'}}>
            <div style={{fontWeight:'700',fontSize:14,display:'flex',alignItems:'center',justifyContent:'space-between',flexDirection:'row',color: '#171717',padding: '4px 0px'}}>
                Youtube 視頻網址 / Youtube ID
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div className="mousehover" style={{margin:'0px 7px'}} onClick={handleUp}><UpArrow/></div>
                    <div className="mousehover" style={{margin:'0px 7px'}} onClick={handleDown}><DownArrow/></div>
                    <div className="mousehover" style={{margin:'0px 7px'}} onClick={handleCopy}><CopySvg/></div>
                    <div className="mousehover" style={{margin:'0px 7px'}} onClick={handleDelete}><BinSvg/></div>
                </div>
            </div>
            <div className='worddetail_container1_row'>
                <input
                    placeholder="https://www.youtube.com/embed/<Video embed ID> or <Video embed ID>"
                    onChange={onEditTitle}
                    maxLength={MAXLENGTH}
                    value={props.data?.url||''}
                    style={{width:'100%',boxSizing:'border-box',padding:'8px 12px 8px 12px',borderRadius:6,border:"1px solid #E5E5E5"}}
                />
            </div>

            {props.data?.url&&<div style={{width:'100%',boxSizing:"border-box",display:'flex',flexDirection:'column',position:'relative',marginTop:10}}>
                <YoutubeEmbed embedId={props.data?.url||''} />
            </div>}
            
            
            <div style={{width:'100%',height:1,backgroundColor:"#AFAFAF",margin:'20px 0px'}}/>
        </div>
	);
}

export default Component 