import React, {useState,useCallback, useEffect} from 'react';
import _ from 'lodash';
import RGL, { WidthProvider } from 'react-grid-layout';

import CrossSvg from '../../svg/CrossSvg';
import MP4Logo from '../../svg/Mp4Logo';
import PicFileLogo from '../../svg/PicFileLogoSvg';
import { server_ip } from '../../config';
const ReactGridLayout = WidthProvider(RGL);
const convertToLayout=(data)=>{return data?.map((item,index)=>({i:`${index}`,x:index,y:0,w:1,h:1,data:item}))||[]}

export default function FileDragableZone(props) {
    const [layout,setlayout]= useState(
        convertToLayout(
            props.isAnimation
                ?props.data.animations
                :props.data?.sections[props.index]?.files
            )
    )
    useEffect(()=>{
        setlayout(convertToLayout(
            props.isAnimation
                ?props.data.animations
                :props.data?.sections[props.index]?.files
        ))
    },[props.data])
    useEffect(()=>{
        //work around for issue of ReactGridLayout not updating width
        window.dispatchEvent(new Event('resize'));
    },[layout]);
    const onDragStop=useCallback((layout_, oldItem, newItem)=>{
        const clonelayout=[...layout_]//_.cloneDeep(layout);
        const index=_.findIndex(layout,{'i':oldItem.i})
        let dragX=clonelayout[index].x
        if(layout[index].x<dragX){
            for(let i in layout){
                if(layout[i].x<=dragX&&layout[i].x>layout[index].x)
                    clonelayout[i].x=layout[i].x-1
            }
        }else if(layout[index].x>dragX){
            for(let i in layout){
                if(layout[i].x>=dragX&&layout[i].x<layout[index].x)
                    clonelayout[i].x=layout[i].x+1
            }
        }else return
        clonelayout[index].x=dragX
        props.updateFileList(layout,clonelayout)
      },[layout])
    const handleRemoveFile=(item,index)=>()=>{
        if(props.handleRemoveFile)
            props.handleRemoveFile(index)
    }
    return (
        <div className='wordedit_draganddropzone'>
            <ReactGridLayout
                className="layout"
                layout={layout}
                cols={layout.length}
                rowHeight={130}
                maxRows={1} 
                preventCollision={true} 
                allowOverlap={true} 
                isDraggable={true}
                margin={[10,10]}
                isResizable={false}
                draggableHandle=".drag-handle" 
                onDragStop={onDragStop}
                style={{minWidth:layout.length*102+2,height:150,margin:0,padding:0,display:'flex',overflowX:'hidden'}}
            > 
            {layout?.map((item,index)=>
                <div key={index} className='wordedit_file_container'>
                    <div className='wordedit_file_image drag-handle'>
                        {   
                            item.data.filename?
                            <img style={{height:84.55,width:84.55,objectFit:'contain'}} src={`${server_ip}/api/documents/${item.data.filename}`} alt={'file image'}/>
                            :<PicFileLogo/>
                        }
                    </div>
                    <div className='wordedit_file_delete_button mousehover' onClick={handleRemoveFile(item,index)}>
                        <CrossSvg/>
                    </div>
                    {item?.data?.originalFilename||item?.data?.name||''}
                </div>
            )}
            </ReactGridLayout>
        </div>
    );
}