import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={12}
      height={16}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 .509l2.25 2.25-1.715 1.716-2.25-2.25L9 .509zm-9 8.99v2.25h2.25l6.224-6.214-2.25-2.25L0 9.5zM0 14h12v1.5H0V14z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgComponent
