import React from "react"
import './LoadingPage.css';

function LoadingPage(props){ 
	const WORDS='Loading......'.split('')
	return(
		<div className='wavybody'>
			<div className='wavy'>
				{WORDS.map((item,index)=><span key={index} style={{'--i':(index+1)}}>{item}</span>)}
			</div>
		</div>
	);
}

export default LoadingPage 