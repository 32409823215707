import React from "react"
import DownArrow from '../../svg/DownArrowSvg2';
import UpArrow from '../../svg/UpArrowSvg';
import BinSvg from '../../svg/BinSvg';
import CopySvg from '../../svg/CopySvg';

const MAXLENGTHTITLE=50;
const MAXLENGTH=300

function Component(props){
    const handleUp=()=>{if(props.handleUp)props.handleUp();}
    const handleDown=()=>{if(props.handleDown)props.handleDown();}
    const handleCopy=()=>{if(props.handleCopy)props.handleCopy();}
    const handleDelete=()=>{if(props.handleDelete)props.handleDelete();}
    const onEditTitle=(e)=>{if(props.onEditTitle)props.onEditTitle(e);}
    const onEditContent=(e)=>{if(props.onEditContent)props.onEditContent(e);}
	return(
		<div style={{width:'100%',boxSizing:'border-box'}}>
            <div style={{fontWeight:'700',fontSize:14,display:'flex',alignItems:'center',justifyContent:'space-between',flexDirection:'row',color: '#171717',padding: '4px 0px'}}>
                {props.isResourceMode?'內容':"標題"}
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div className="mousehover" style={{margin:'0px 7px'}} onClick={handleUp}><UpArrow/></div>
                    <div className="mousehover" style={{margin:'0px 7px'}} onClick={handleDown}><DownArrow/></div>
                    <div className="mousehover" style={{margin:'0px 7px'}} onClick={handleCopy}><CopySvg/></div>
                    <div className="mousehover" style={{margin:'0px 7px'}} onClick={handleDelete}><BinSvg/></div>
                </div>
            </div>
            <div className='worddetail_container1_row'>
                <input
                    placeholder="段落標題"
                    onChange={onEditTitle}
                    maxLength={MAXLENGTHTITLE}
                    value={props.data?.title||''}
                    style={{width:'100%',boxSizing:'border-box',padding:'8px 12px 8px 12px',borderRadius:6,border:"1px solid #E5E5E5"}}
                />
            </div>
            {props.isResourceMode||<div style={{fontWeight:'700',fontSize:14,display:'flex',alignItems:'center',flexDirection:'row',color: '#171717',padding: '4px 0px',marginTop:20}}>
                內容
            </div>}
            <div className='worddetail_container1_row'>
                <textarea 
                    value={props.data?.content||''}
                    onChange={onEditContent}
                    className='wordedit_textarea'
                    placeholder="在此處輸入內容..."
                    maxLength={MAXLENGTH}
                />
                <div className='wordedit_textarea_wordcount'>
                    {props.data?.content?.length||0}/{MAXLENGTH}
                </div>
            </div>
            <div style={{width:'100%',height:1,backgroundColor:"#AFAFAF",margin:'20px 0px'}}/>
        </div>
	);
}

export default Component 