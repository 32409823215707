import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={21}
      height={21}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={10.1461}
        cy={10.1461}
        r={9.89242}
        fill="#F9FAFB"
        stroke="#111827"
        strokeWidth={0.507303}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.689 13.51a.64.64 0 11-.906-.906l2.458-2.458-2.458-2.458a.64.64 0 11.906-.906l2.458 2.458 2.458-2.458a.64.64 0 01.905.906l-2.458 2.458 2.458 2.458a.64.64 0 01-.905.905l-2.458-2.458-2.458 2.458z"
        fill="#111827"
      />
    </svg>
  )
}

export default SvgComponent
