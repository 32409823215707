import React, {useState,useEffect} from 'react';
import { connect } from 'react-redux';
import EditIcon from '../svg/EditSvg';
import './WordsDetailPage.css'
import { useNavigate, useParams } from 'react-router-dom';
import { SECTION_TYPE } from '../dto/section.dto';
import {fetchWordById} from '../services/request.service';
import LoadingPage from './components/LoadingPage';
import {server_ip} from '../config';
import {QRCodeCanvas} from 'qrcode.react';
import DownloadSvg from '../svg/DownloadSvg';
import YoutubeEmbed from './components/YoutubeEmbed';

function Component(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data,setdata] = useState({
    text: "unknown"
  })
  const [isLoading,setisLoading]=useState(false)
  const fetchData=async(force=false)=>{
    if(isLoading&&!force)return
    setisLoading(true)
    let result = await fetchWordById(props.token.token,id);
    if(result.result){
        setdata(result.result)
    }else{
        alert('Fetch data error')
    }
    setisLoading(false)
  }
  useEffect(()=>{
    fetchData();
  },[id])

  const handleEditBtn=()=>{
    navigate(`/words/edit/${id}`);
  }
  const handleDownloadQrCode=()=>{
    const canvas = document.querySelector('.HpQrcode > canvas')
    var element = document.createElement('a');
    element.href = canvas.toDataURL();
    element.download = `${data.text}_qrcode.png`;
    document.body.appendChild(element);
    element.click();
  }
  return (
    <div className='wordslist_root_'>
        <div className='wordslist_root'>
            <div className='row_spacebetween'>
                <div className='common_title'>字卡: {data?.text}</div>
                <div className='common_button mousehover' onClick={handleEditBtn}>
                    <EditIcon/>
                    編輯
                </div>
            </div>

            <div className='worddetail_main'>
                <div className='worddetail_left'>
                    <div className='worddetail_strokecontainer' style={{marginBottom:10}}>
                        筆畫: {data.strokes||'0'}
                    </div>
                    {data?.sections?.map((item,index)=>
                        item.type===SECTION_TYPE.WORDCARD?
                        <div key={index} style={{width:'100%',boxSizing:"border-box",display:'flex',flexDirection:'column'}}>
                            <div className='worddetail_subtitle'>
                                字
                            </div>
                            {item.cards?.map((card,cardIndex)=>
                                <div key={cardIndex} className='worddetail_container1_row'>
                                    <div className='worddetail_container1_row_key'>
                                        {card?.key||' '}
                                    </div>
                                    <div className='worddetail_container1_row_value'>
                                        {card?.value||' '}
                                    </div>
                                </div>
                            )}
                        </div>
                        :item.type===SECTION_TYPE.MEDIA
                        ?<div key={index} style={{width:'100%',boxSizing:"border-box",display:'flex',flexDirection:'column',position:'relative'}}>
                            <div className='worddetail_imagecontainer' style={{justifyContent:'flex-start',overflowX:'auto',flexWrap:"wrap"}}>
                                {item?.files?.map((file,fileIndex)=>
                                    <img key={fileIndex} style={{height:110,maxHeight:110,minHeight:110,width:110,minWidth:110,maxWidth:110,objectFit:'contain',marginBottom:10,margin:3}} src={`${server_ip}/api/documents/${file?.filename}`} alt='image'/>
                                )}
                            </div>
                        </div>
                        :item.type===SECTION_TYPE.VIDEOURL
                        ?<div key={index} style={{width:'100%',boxSizing:"border-box",display:'flex',flexDirection:'column',position:'relative'}}>
                            <YoutubeEmbed embedId={item.url||''} />
                        </div>
                        :<div key={index} style={{width:'100%',boxSizing:"border-box",display:'flex',flexDirection:'column'}}>
                            <div className='worddetail_subtitle'>
                                {item.title}
                            </div>
                            <div style={{fontSize:14,maxWidth:"100%",whiteSpace:'pre-wrap'}}>{item.content}</div>
                        </div>
                    )}
                    <div style={{height:300,width:"100%"}}></div>
                </div>
                <div className='worddetail_right'>
                    <div className='worddetail_subtitle'>
                        字卡圖片
                    </div>
                    <div className='worddetail_imagecontainer' style={{position:"relative"}}>
                        {
                            data?.image?.filename
                           &&<img style={{height:200,width:200,objectFit:'contain'}} src={`${server_ip}/api/documents/${data?.image?.filename}`} alt='image'/>   
                        }
                    </div>

                    <div className='worddetail_subtitle'>
                        筆劃動畫
                    </div>
                    <div style={{width:'100%',boxSizing:"border-box",display:'flex',flexDirection:'column',position:'relative'}}>
                        <div className='worddetail_imagecontainer' style={{justifyContent:'flex-start',overflowX:'auto',flexWrap:"wrap"}}>
                            {data?.animations?.map((file,fileIndex)=>
                                <img key={fileIndex} style={{height:110,maxHeight:110,minHeight:110,width:110,minWidth:110,maxWidth:110,objectFit:'contain',marginBottom:10,margin:3}} src={`${server_ip}/api/documents/${file?.filename}`} alt='image'/>
                            )}
                        </div>
                    </div>
                    <div className='worddetail_subtitle'>
                        QR code
                    </div>
                    <div className='HpQrcode worddetail_imagecontainer' style={{position:'relative'}}>
                        <QRCodeCanvas value={`TXT:${data?.text}SEN:${id}`} />
                        <div className='mousehover' style={{position:'absolute',bottom:5,right:5}} onClick={handleDownloadQrCode}>
                            <DownloadSvg/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {isLoading&&<LoadingPage/>}
    </div>
  );
}

export default connect((store)=>{
    return{
      token:store.tokenReducer,
    }
  })(Component)
  