import React, { useState, useEffect} from 'react';
import {batchInsertWords} from '../../services/request.service'
import { connect } from 'react-redux';

import LoadingPage from './LoadingPage';
import AddSvg from '../../svg/AddSvg';
import DeletIconSvg from '../../svg/CrossSvg';

const isTraditional = (c) => /^[\u3006\u3007\u4e00-\u9fff\u3400-\u4dbf]|[\ud840-\ud868\ud86a-\ud879\ud880-\ud883][\udc00-\udfff]|\ud869[\udc00-\udedf\udf00-\udfff]|\ud87a[\udc00-\udfef]|\ud884[\udc00-\udf4f]$/.test(c);

function Component(props) {
    const [isloading,setisloading]=useState(false);
    const [list,setlist]=useState([{text:''}]);
    const handleNewEntry=()=>{setlist([...list,{text:''}])};
    const handleCancel=()=>{if(props.handleCancel)props.handleCancel()};
    const handleReset=()=>{setlist([{text:''}])};
    const handleDeleteItem=(item,index)=>()=>{
        let L = [...list];
        L.splice(index, 1);
        setlist(L);
    }
    const handleWordChange=(item,index)=>(e)=>{
        let L = [...list];
        L[index].text=e.target.value;
        setlist(L);
    }
    const handleConfirm=async ()=>{
        if(isloading)return
        for(let word of list){
            if(word.text===''||!word.text){
                return alert('Character field cannot be empty.')
            }else if(word.text.length>1){
                return alert(`Only one Character is allowed for each input field. Detected words: ${word.text}`)
            }else if(!isTraditional(word.text)){
                return alert(`${word.text} is not a Chinese character`);
            }
        }

        setisloading(true)
        try{
            await batchInsertWords(props.token.token,list)
            props.handleCancel(true)
        }catch(e){
            console.log(e)
            alert('Error')
            setisloading(false)
        }finally{
        }
    }
    return (
        <div style={{position:'fixed',top:0,bottom:0,left:0,right:0,backgroundColor: 'rgba(0, 0, 0, 0.5)',display:'flex',justifyContent:"center",alignItems:'center'}}>
            <div style={{padding:'30px 32px',display:'flex',width:'100%',maxWidth:700,backgroundColor:'white',borderRadius:10,flexDirection:'column',alignItems:'center',boxSizing:'border-box',position:'relative'}}>
                {/*Title*/}
                <div style={{color: '#171717',fontWeight:800,fontSize:36,width:"100%",boxSizing:'border-box',textAlign:'left',userSelect: 'none'}}>
                    新增字卡
                </div>
                {/*End of Title*/}

                {/*Main list*/}
                <div style={{width:'100%',flexDirection:"column",display:'flex',boxSizing:'border-box',maxHeight:300,marginTop:50,overflowY:'auto'}}>
                    {list.map((item,index)=>
                        <div key={index} style={{position:"relative",width:'100%',boxSizing:'border-box',margin:'8px 0px',paddingRight:8}}>
                            <input 
                                value={list[index]?.text||''}
                                maxLength={1}
                                onChange={handleWordChange(item,index)}
                                style={{border: '1px solid #E5E5E5',boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',borderRadius: 6,width:'100%'}}
                            />
                            <div 
                                className='mousehover'
                                onClick={handleDeleteItem(item,index)}
                                style={{position:'absolute',top:-8,right:0,width:20,height:20,borderRadius:10,border:'0.507303px solid #111827',justifyContent:'center',alignItems:'center',display:'flex',boxSizing:'border-box',backgroundColor:"white"}}
                            >
                                <DeletIconSvg/>
                            </div>
                        </div>
                    )}
                </div>
                {/*End ofMain list*/}

                {/*Add Item*/}
                <div 
                    className='mousehover'
                    onClick={handleNewEntry}
                    style={{width:28,height:28,backgroundColor:"#E7E7E7",display:'flex',justifyContent:'center',alignItems:"center",borderRadius:'50%',marginTop:20}}
                >
                    <AddSvg color='#6366F1'/>
                </div>
                {/*End of Add Item*/}

                {/*Buttons*/}
                <div style={{width:'100%',boxSizing:"border-box",display:'flex',flexDirection:'row',justifyContent:'flex-end',marginTop:50}}>
                    <div
                        className='mousehover'
                        onClick={handleReset}
                        style={{padding:'10px 16px',borderRadius:6,color:'#EF4444',border:'1px solid #EF4444',userSelect: 'none'}}
                    >
                        重置
                    </div>
                    <div
                        className='mousehover'
                        onClick={handleCancel}
                        style={{padding:'10px 16px',borderRadius:6,color:'#404040',border:'1px solid #E5E5E5',margin:"0px 20px",userSelect: 'none'}}
                    >
                        取消
                    </div>
                    <div
                        className='mousehover'
                        onClick={handleConfirm}
                        style={{padding:'10px 16px',backgroundColor:'#6366F1',borderRadius:6,color:'white',border:'1px solid #6366F1',userSelect: 'none'}}
                    >
                        確認
                    </div>
                </div>
                {/*End of Buttons*/}

                <div className='mousehover' onClick={handleCancel} style={{position:'absolute',top:10,right:15}}>
                    <DeletIconSvg length={17} color="#737373"/>
                </div>
            </div>
            {isloading&&<LoadingPage/>}
        </div>
    );
}

export default connect((store)=>{
    return{
      token:store.tokenReducer,
    }
  })(Component)