import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 8l-1.41-1.41L9 12.17V0H7v12.17L1.42 6.58 0 8l8 8 8-8z"
        fill="#171717"
      />
    </svg>
  )
}

export default SvgComponent
