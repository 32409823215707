const { server_ip }= require('../config');

async function validToken(token){
    try {
        const response= await fetch(`${server_ip}/api/valid`,{
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
        return await response.json();
    } catch (error) {
        alert(JSON.stringify(error));
    }
}

async function loginWithPassword(username,password){
    try {
        const response= await fetch(`${server_ip}/api/users/login`,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username,password
            }),
        })
        return await response.json();
    } catch (error) {
        alert(JSON.stringify(error));
    }
}

async function batchInsertWords(token,list){
    try {
        const response= await fetch(`${server_ip}/api/words/batchInsertWords`,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                list
            }),
        })
        return await response.json();
    } catch (error) {
        alert(JSON.stringify(error));
    }
}
async function fetchWordList(token,filter,option){
    try {
        const response= await fetch(`${server_ip}/api/words/fetch`,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                filter,
                option
            }),
        })
        return await response.json();
    } catch (error) {
        alert(JSON.stringify(error));
    }
}

async function deleteWordsByIds(token,ids){
    try {
        const response= await fetch(`${server_ip}/api/words/deleteByIds`,{
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                ids
            }),
        })
        return await response.json();
    } catch (error) {
        alert(JSON.stringify(error));
    }
}

async function fetchWordById(token,id){
    try {
        const response= await fetch(`${server_ip}/api/words/${id}`,{
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        return await response.json();
    } catch (error) {
        alert(JSON.stringify(error));
    }
}

async function updateWordById(token,id,data){
    try {
        const response= await fetch(`${server_ip}/api/words/${id}`,{
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        alert(JSON.stringify(error));
    }
}

async function uploadPhoto(token,formData){
    try {
        let response= await fetch(`${server_ip}/api/documents/upload`, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
            //'content-type': 'multipart/form-data',
          },
        });
        return await response.json();
    } catch (error) {
        alert(JSON.stringify(error));
    }
}
async function insertResource(token,data){
    try {
        const response= await fetch(`${server_ip}/api/resources`,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        alert(JSON.stringify(error));
    }
}

async function fetchResourcesList(token,filter,option){
    try {
        const response= await fetch(`${server_ip}/api/resources/fetch`,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                filter,
                option
            }),
        })
        return await response.json();
    } catch (error) {
        alert(JSON.stringify(error));
    }
}

async function fetchResourcesById(token,id){
    try {
        const response= await fetch(`${server_ip}/api/resources/${id}`,{
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        return await response.json();
    } catch (error) {
        alert(JSON.stringify(error));
    }
}

async function updateResourceById(token,id,data){
    try {
        const response= await fetch(`${server_ip}/api/resources/${id}`,{
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        alert(JSON.stringify(error));
    }
}


async function deleteResourcesByIds(token,ids){
    try {
        const response= await fetch(`${server_ip}/api/resources/deleteByIds`,{
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                ids
            }),
        })
        return await response.json();
    } catch (error) {
        alert(JSON.stringify(error));
    }
}
async function changePassword(token,password,oldPassword){
    try {
        const response= await fetch(`${server_ip}/api/users/changePW`,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                password,
                oldPassword
            }),
        })
        return await response.json();
    } catch (error) {
        alert(JSON.stringify(error));
    }
}
async function fetchUserList(token,option={}){
    try {
        const response= await fetch(`${server_ip}/api/users/admin/list?option=${JSON.stringify(option)}`,{
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
        return await response.json();
    } catch (error) {
        alert(JSON.stringify(error));
    }
}
async function adminChangePassword(token,userId,password){
    try {
        const response= await fetch(`${server_ip}/api/users/admin/changePW`,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                password,
                userId
            }),
        })
        return await response.json();
    } catch (error) {
        alert(JSON.stringify(error));
    }
}

export {
    validToken,
    loginWithPassword,
    batchInsertWords,
    fetchWordList,
    deleteWordsByIds,
    fetchWordById,
    updateWordById,
    uploadPhoto,
    insertResource,
    fetchResourcesList,
    fetchResourcesById,
    updateResourceById,
    deleteResourcesByIds,
    changePassword,
    fetchUserList,
    adminChangePassword
}