import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 .4C6.62.4 7.12.9 7.12 1.52v3.36h3.36a1.12 1.12 0 010 2.24H7.12v3.36a1.12 1.12 0 11-2.24 0V7.12H1.52a1.12 1.12 0 010-2.24h3.36V1.52C4.88.9 5.382.4 6 .4z"
        fill="#6366F1"
      />
    </svg>
  )
}

export default SvgComponent
