import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_493_68828)">
        <path fill="#fff" fillOpacity={0.01} d="M0 0H16V16H0z" />
        <path
          d="M3 9.499a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
          fill="#0D6EFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_493_68828">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgComponent
